<template>
    <div>
        <Header> 
            <template v-slot:slot-icon>
                <span id="bt-main-search" :class="'icon-search-top me-3'  + (openBoxSearch ? ' active' : '') + (isUserFrench ? ' page-french' : '')" @click="handleClickBoxSearch"></span>
            </template>
        </Header>  

        <div :class="'box-search' + (openBoxSearch ? ' active' : '') + (isUserFrench ? ' page-french' : '') " id="navbarsSearch">
            <BoxSearch/>
        </div>
                                
        <div :class="'container-fluid' + ($route.query.zoom ? ' fullscreen' : '') + (isUserFrench ? ' page-french' : '') ">
            <div class="row">
                <div class="main-box-fixed">  
                    <div class="box-fixed" v-show="!active_filter">
                        <div class="title">
                            <h1 :class="countAllFilter > 99 ? 'three-digits' : ''">
                                <template v-if="countAllFilter < count && (countAllFilter > 0 || (countAllFilter === 0 && Object.keys(countFilter).length > 0 && countFilter.constructor === Object))">
                                    {{ countAllFilter }} / 
                                </template>
                                {{ count }}</h1>
                            <h2>{{$t('Result')}}</h2>
                        </div>
                        <!-- Filters --> 
                        <div class="filters-plus" @click="active_filter=true" v-if="!change_agency_sort">
                            <img :src="require(`@/assets/dist/2019/images/icon/btn-filtre-off${isUserFrench ? '-fr' : ''}.svg`)" alt="" >
                            <p>{{$t('Filters')}}</p>
                        </div>
                        <!-- Save order Agency -->
                        <div class="filter-inner apply" @click="save_agency_sort()" v-else>
                            <img :src="require(`@/assets/dist/2019/images/icon/btn-ok${isUserFrench ? '-fr' : ''}.svg`)" alt="" >
                            <p>{{$t('Apply')}}</p>
                        </div>
                        <div class="filter-inner reset" @click="resetFilters()" v-if="graph_filter || filters !== ''">
                            <img src="@/assets/dist/2019/images/icon/checklist-reload.svg" alt="">
                            <p>{{$t('Reset')}}</p>
                        </div>
                    </div>
                    
                    <filterSection v-if="active_filter" @active="cancel" :class="'menu-filter ' + (active_filter ? 'active' : '')" :filter_sort="filter_sort" :graph_filter="graph_filter" @resetFilters="resetFilters" />

                    <div class="box-fix-left" v-bind:class="{has_filter: graph_filter || filters !== ''}" ref="menuFlag">
                        <ul>
                            <label class="flag-active" style="display: block;">  
                                <draggable :list="listAgencyActive_temp" group="icon-active" v-bind="dragOptions" @change="change_agency_sort = true" itemKey="agency">
                                    <template #item="{ element }">                 
                                    <li class="active">
                                        <div @click="setAgency(element.agency_model)">
                                            <img v-if="element.agency_model" :alt="element.agency_model" :src="require('@/assets/images/flag/new/'+element.icon_flags+'.svg')" width="35" height="35" style="margin-bottom: 5px;" />
                                            <p> 
                                                <span style="margin-right: 0;"></span>
                                                <span>
                                                    <template v-if="countAllFilter < count && (countAllFilter > 0 || (countAllFilter === 0 && Object.keys(countFilter).length > 0 && countFilter.constructor === Object))">
                                                        {{ getCountFilter((element.agency_model)) }} / 
                                                    </template>
                                                    {{ getCountAgency(element.agency_model) }}</span>
                                                {{$t(element.acronym)}}
                                            </p>
                                            <p class="short_name" v-if="element.alpha_3_code">({{element.alpha_3_code}})</p>
                                        </div>
                                        <span class="moins icon-minus" v-if="$route.params.type != 'advanced_trs_ceesp'" @click="setUnActiveAgency(element.agency_model)"></span>
                                    </li>
                                    </template>
                                </draggable>                             
                            </label>

                            <li v-for="(agency, key) in sortByCountResults(listAgencyActive(false))" :key="key" @click="setActiveAgency(agency.agency_model)" style="opacity : 0.5;" :class="{'unclickable' : getCountAgency(agency.agency_model) === 0}">
                                <img v-if="agency.agency_model" :alt="agency.agency_model" :src="require('@/assets/images/flag/new/'+agency.icon_flags+'.svg')" width="35" height="35" style="margin-bottom: 5px;" />
                                <p> 
                                    <span style="margin-right: 0;"></span>
                                    <span> {{ getCountAgency(agency.agency_model) }}</span>
                                    {{ $tc(agency.acronym) ? $t(agency.acronym) : agency.acronym}}
                                </p>
                                <p class="short_name" v-if="agency.alpha_3_code">({{agency.alpha_3_code}})</p>
                                <span class="moins icon-plus" v-if="$route.params.type != 'advanced_trs_ceesp' && getCountAgency(agency.agency_model) > 0"></span>
                            </li>
                        </ul>
                    </div>
                </div>
            

                <div class="main-body" id="table-result">
                    <div class="body-fix">
                        <div class="body-custom">
                            <div class="box-btn-menu">                                
                                <a href="javascript:void(0)" @click="selectTab('overview')" :class="'btn ' + (tabs.overview ? ' btn-orange' : 'btn-border-orange')" >{{$t('Overview')}}</a>
                                <a href="javascript:void(0)" @click="selectTab('expert')" :class="'btn ' + (tabs.expert ? ' btn-orange' : 'btn-border-orange')"  >{{$t('Expert')}}</a>
                                <a v-if="user && user.access_clinical" href="javascript:void(0)" @click="selectTab('essais_clinique')" :class="'btn ' + ($route.query.tab && $route.query.tab == 'essais_clinique' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Clinical trials')}}</a>
                                <a href="javascript:void(0)" v-if="isUserFrench" @click="selectTab('prix')" :class="'btn ' + ($route.query.tab && $route.query.tab == 'prix' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Prix')}}</a>
                                <a href="javascript:void(0)" v-if="isUserInter && (user && user.limit_access_report)" @click="selectTab('report')" :class="'btn ' + ($route.query.tab && $route.query.tab == 'report' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Report')}}</a>
                            
                            </div>
                            
                        </div>
                    </div>

                        <!-- Tab Overview -->
                        <TabOverviewGraph v-if="tabs.overview && isUserInter && !$route.query.zoom" @request_result_agency="request_result_agency"/>

                        <!-- <TabOverview v-if="tabs.overview" :ListAgency="listAgency"/> -->
                        <TabOverview v-if="tabs.overview" @request_result_agency="request_result_agency"/>

                        <!-- Tab expert -->
                        <TabExpert v-if="tabs.expert" :listAgencyDropdown="listAgencyActive_temp"/>

                        <TablePrix v-if="$route.query.tab == 'prix' && isUserFrench" main_page="Simple" />
                        
                        <TableEssaisClinique v-if="$route.query.tab == 'essais_clinique'" main_page="Simple" @graph_filter_callback="graph_filter_callback" />
                        
                        <TabReport v-if="$route.query.tab == 'report' && isUserInter && user.limit_access_report" main_page="Simple" />

                </div>
            </div>
        </div> 

        <!-- Loading -->
        <Loader :isLoad="isLoad" />

        <Footer />

        <div v-if="active_filter || change_agency_sort" class="modal-backdrop fade in"></div>

        <ModalSaveKeyword :type="$route.params.type"/>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import ModalSaveKeyword from '@/components/elements/modal_save_keyword.vue';
import Header from '@/components/Layout/Header.vue'; 
import Footer from '@/components/Layout/Footer.vue'; 
import BoxSearch from '@/components/result/box_search.vue';
import filterSection from '@/components/result/filter.vue';
import { isUserFrench, currentDate, hide_empty_row, eventHandlerResize, isUserInter } from '@/utils';
import { Base64 } from 'js-base64'
import draggable from 'vuedraggable'
import Loader from "@/components/Layout/Loader";
import _ from 'lodash'

/**
 * Results search container
 */
export default {
    name: 'result',
    components: {        
        Header,
        Footer,
        TabOverview : defineAsyncComponent(() => import('@/components/result/tab_overview.vue')),
        TabOverviewGraph : defineAsyncComponent(() => import('@/components/result/tab_overview_graph.vue')), 
        TabExpert : defineAsyncComponent(() => import(/* webpackChunkName: "tab_expert" */ '@/components/result/tab_expert.vue')),
        BoxSearch,
        filterSection,
        draggable,
        TablePrix : defineAsyncComponent(() => import('@/components/advanced_result/table_prix.vue')),
        TabReport : defineAsyncComponent(() => import('@/components/result/tab_report.vue')),
        TableEssaisClinique : defineAsyncComponent(() => import('@/components/advanced_result/table_essais_clinique.vue')),
        ModalSaveKeyword,
        Loader
    },
    data() { 
        return {             
            tabs : {
                overview : this.$route.query.tab == 'overview' ? true : false,
                expert : this.$route.query.tab == 'expert' ? true : false,
                essais_clinique : this.$route.query.tab == 'essais_clinique' ? true : false
            }, 
            active_filter : false,
            last_agency : '',
            count: 0,
            countAllFilter: 0,
            submit_filter : false,
            listAgencyActive_temp :  [],
            change_agency_sort : false,
            graph_filter: false,
            original_base64: null,
            reset_filter: false
        }
    },
    mounted(){ 
        //search
        this.$store.dispatch("result/result", this.$route.params) 
        
        if(this.$route.params.type == 'inter'){
            this.$store.dispatch("agency/getgroupAgency")
        }

       
        console.log('step 2'); 
        document.body.classList.add('body-bg-gray')
          
        if(this.$route.query.tab){
            this.selectTab(this.$route.query.tab, false)
        }else{
            this.$router.replace({ query: Object.assign({}, this.$route.query, {tab : 'expert' }) });
        } 
        if(this.$route.query.zoom){
            this.eventHandlerResize()
        }

    }, 
    computed:{    
        /**
         * Get user informations
        */
        user(){
            return this.$store.getters['auth/user']
        },       
        isUserFrench,
        isUserInter,  
        /**
         * Get all agencies
        */  
        listAgency(){
            return this.$store.getters['result/listAgency']
        },   
        /**
         * Get total count results from search agencies 
        */  
        countAll() {
            if(this.$route.params.type == "inter"){
                let count = 0
                for(let key in this.searchAgency){
                    count += this.getCountAgency(this.searchAgency[key])
                }
                return count
            }
            return this.$store.getters['result/count_all']
        },
        /**
         * Get count results by agency
        */ 
        countAgency(){
            return this.$store.getters['result/count_agency']
        },
        countFilter(){
            return this.$store.getters['result/count_filter']
        },
        /**
         * Get main agency
        */ 
        Agency(){
            return this.$store.getters['result/Agency']
        },
        /**
         * Get agencies for the current search
        */ 
        searchAgency(){
            return this.$store.getters['result/search_agency']
        },
        /**
         * Get access agencies for current user
        */ 
        accessAgency(){
            return this.$store.getters['result/access_agency']
        },

        /**
         * Allocate agencies depends on status (active or inactive)
        */ 
        listAgencyActive(){
            console.log('listAgencyActive');
            // const accessAgency = this.$store.getters['result/access_agency'];
            let listAgencyActive = [];
            let listAgencyUnActive = [];
            for(let item in this.listAgency){
                if(this.accessAgency.indexOf(this.listAgency[item].agency_model) !== -1){
                    let searchedAgenciesTest = (this.agency_sort.length == 0) ? this.searchAgency.indexOf(this.listAgency[item].agency_model) !== -1 : this.listAgencyActive_temp.find(a => a.agency_model === this.listAgency[item].agency_model)
                    if(searchedAgenciesTest && this.countAgency.find(a => a.agency_model === this.listAgency[item].agency_model && a.count > 0)){
                        listAgencyActive.push(this.listAgency[item])
                    }else{
                        listAgencyUnActive.push(this.listAgency[item])
                    }
                }     
            } 
            return active => active ? listAgencyActive : listAgencyUnActive
        },  

        /**
         * Get search box opening status
        */ 
        openBoxSearch () {  
            return this.$store.getters['header/openBoxSearch']
        },

        isLoad() {  
            if(this.$store.getters['result/load'] || this.$store.getters['result/load_agency'] || this.$store.getters['advanced_result/load'] || this.$store.getters['advanced_result/prix_load'] || this.$store.getters['essais_clinique/load'] || this.$store.getters['report/load']){
                return true
            }
            return false
        },
        filters(){
            return this.$store.getters['result/filters'];
        },
        /**
         * Set options for draggable component (flags in sidebar)
        */ 
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        agency_sort() {
            return this.$store.getters['result/agency_sort']
        },
        filter_sort() {
            return ['communs'].concat(this.listAgencyActive_temp.map(function(e) { return e.agency_model; }))
        },
        select_export_obj(){
            return this.$store.getters['result/select_export']
        },
    },
    watch: {
        Agency: function(newAgency, oldAgency) {
            console.log('Agency change from ' + oldAgency + ' to ' + newAgency)

            // query result_agency when data is null and not submit filter
            if(this.$store.getters['result/data'] === undefined && this.Agency && !this.submit_filter){
                this.request_result_agency({order_by:'decision_date_num', sort:'DESC', graph_filter: this.graph_filter});
                console.log('get result');
                //this.$store.dispatch("result/result_agency", {...this.$route.params, agency : this.Agency, filter : Base64.encode(this.filters), submit_filter : this.submit_filter} )
            }
        },
        countAll(){
            this.count = this.countAll
        },
        countFilter(){
            if(!_.isEmpty(this.countFilter)) {
                let total = 0
                Object.entries(this.countFilter).forEach((items) => {
                    const [key, value] = items;
                    Object.values(this.searchAgency).forEach((agency) => {
                        if(key==agency){
                            total += value
                        }
                    })
                })
                this.countAllFilter = total
            } else
                this.countAllFilter = 0
        },
        $route : function(to, from) {
            if( (to.path === from.path) && (to.fullPath !== from.fullPath) ){        
                console.log('route change', to.query);  

                // this.setAgency(to.query.agency, false)
                this.selectTab(to.query.tab, false)
            }
        },
        /**
         * Request result_agency only if graph_filter is not set to true (to prevent double request when regular and graph filters are true)
         */
        filters(){
            if(!this.reset_filter){
                this.submit_filter = true
                const hasOnlyCommunsFilters = this.set_agency_by_filter()
                if(hasOnlyCommunsFilters)
                    this.$router.replace({ query: Object.assign({}, this.$route.query, {tab : 'overview', agency : 'Overview' }) });

            
                this.setAgency((hasOnlyCommunsFilters || this.filters === "") ? 'Overview' : this.last_agency)
            }
            this.reset_filter = false
        },
        listAgencyActive(){
            if(this.listAgencyActive_temp.length == 0 && (!this.agency_sort || this.agency_sort.length == 0)){
                this.listAgencyActive_temp = this.listAgencyActive(true)
            }
        },
        agency_sort(){
            if(this.agency_sort && this.agency_sort.length > 0){
                if(this.searchAgency && this.searchAgency.length > 0) { //if search contains agencies 
                    let agenciesSort = []
                    this.agency_sort.slice().reverse().forEach(agencySort => {
                        if(this.searchAgency.includes(agencySort.agency_model) && !agenciesSort.find(a => a.agency_model === agencySort.agency_model) && this.countAgency.find(a => a.agency_model === agencySort.agency_model && a.count > 0)){
                            agenciesSort.unshift(agencySort)
                        } else {
                            this.searchAgency.forEach(agency => {
                                let formattedAgency = this.listAgency.find(function(e) { return e.agency_model === agency; })
                                if(!agenciesSort.find(a => a.agency_model === agency) && !this.agency_sort.find(a => a.agency_model === agency) && this.countAgency.find(a => a.agency_model === agency && a.count > 0)){
                                    agenciesSort.push(formattedAgency)
                                }  
                            })
                        }
                    })
                    this.listAgencyActive_temp = agenciesSort
                    return;
                }
            }
        },
        listAgencyActive_temp(){
            if(this.last_agency == '' && this.$route.query.tab === 'expert' && this.listAgencyActive_temp && this.listAgencyActive_temp[0]){
                this.last_agency = this.listAgencyActive_temp[0].agency_model
                this.$router.replace({ query: Object.assign({}, this.$route.query, {tab : 'expert', agency :  this.last_agency }) });
            }
        },
    },
    updated(){
        
        console.log('result.vue updated')

        // hide_empty_row
        if(document.querySelector('#table_'+this.Agency)){ 
            this.hide_empty_row('#table_'+this.Agency)         
        }   

        // when agency in URL not active
        if(this.$route.query.agency && this.searchAgency.length > 0 && !this.searchAgency.includes(this.$route.query.agency)){
            this.setActiveAgency(this.$route.query.agency, false) 
        }

        // query result_agency when submit filter
        if(this.Agency && this.submit_filter){
            this.$store.dispatch('result/cleardata')            
            this.request_result_agency({order_by:'decision_date_num', sort:'DESC'});
            //this.$store.dispatch("result/result_agency", {...this.$route.params, agency : this.Agency, filter : Base64.encode(this.filters), submit_filter : this.submit_filter} )
            this.submit_filter = false
        }


        if(this.$route.query.tab === 'expert' && this.last_agency && !this.$route.query.agency){
            this.$router.replace({ query: Object.assign({}, this.$route.query, {tab : 'expert', agency :  this.last_agency }) });
        }

    },
    methods: {
        /**
         * Triggers on events which need data reloading
         * Parse, format and push data
         */
        request_result_agency(param = {}){
            let paramObj = {...this.$route.params, ...param}
            paramObj['agency'] = this.Agency
            paramObj['filter'] = ('filter' in param) ? param['filter'] : Base64.encode(this.filters)
            paramObj['submit_filter'] = this.submit_filter 

            let keywords = Base64.decode(paramObj['base64Search']);
            if(param.graph_filter){
                let keywordsArr = keywords.split('|')
                for( var i = 0; i < keywordsArr.length; i++){              
                    if ( (keywordsArr[i].includes('therapeutic') && 'therapeutic' in param) || 
                    (keywordsArr[i].includes('name') && 'name' in param) || 
                    (keywordsArr[i].includes('agency') && 'agency' in param)
                    ) { 
                        keywordsArr.splice(i, 1); 
                        i--; 
                    }
                }
                keywords = keywordsArr.join('|')
            }

            if('graph_filter' in param)
                this.graph_filter = param.graph_filter

            if(!this.original_base64)
                this.original_base64 = paramObj['base64Search']

            let graphFilters = ''
            if('decision' in param)
                graphFilters = 'decision=' + param.decision + '|'

            if('therapeutic' in param) {   
                graphFilters += 'therapeutic=' + param.therapeutic + '|'
            } else if('name' in param) {
                graphFilters += 'name=' + param.name + '|'
            }
            paramObj['base64Search'] = Base64.encode(graphFilters + keywords).replace('/\-/g', '+').replace('/_/g', '/') // eslint-disable-line

            if(graphFilters !== ''){ // update base64 in route if graph filters are applied
                this.$router.replace({ query: Object.assign({}, this.$route.query), params: {base64Search: paramObj['base64Search']} });
            }

            if(this.select_export_obj && this.select_export_obj.isSelect){
                paramObj['select_export'] = this.select_export_obj.listID
            }
            if(paramObj['export_excel']){
                delete paramObj.export_excel;
                this.$store.dispatch(`result/export_excel`, [paramObj, currentDate()+"_"+paramObj['agency'] ] )
            }else{
                this.$store.dispatch(`result/result_agency`, paramObj )
            }
        },
        hide_empty_row,
        eventHandlerResize,
        handleClickBoxSearch(){
            this.$store.dispatch("header/setOpenBoxSearch", !this.openBoxSearch)
        },
        selectTab(tab, push = true){
            for(let d in this.tabs){
                this.tabs[d] = false; 
            }

            if(tab == 'overview'){
                this.setAgency('Overview')
            }else if(tab == 'prix' || tab == 'essais_clinique' || tab == 'report'){
                // this.setAgency('ClinicalStudy', false)
            }else{
                if(this.$route.query.agency && this.$route.query.agency !== 'Overview' && this.$route.query.agency !== this.last_agency){
                    this.last_agency = this.$route.query.agency
                }
                this.setAgency(this.last_agency, false)
            }

            if(push && this.$route.query.tab !== tab){ 
                let query = Object.assign({}, this.$route.query);
                query.tab = tab
                if(query.tab !== 'expert'){
                    delete query.agency
                }else{
                    query.agency = this.last_agency
                }
                this.$router.push({ query });
            }
            this.tabs[tab] = true;
        },  
        /**
         * Set main agency (last)
         * @property {string} agency - agency to set default
         * @property {boolean} push - if reload is needed
         *  */ 
        setAgency(agency, push = true){    
            console.log('setAgency', agency)
            this.$store.dispatch("result/setAgency", agency)

            if(agency == 'Overview'){
                this.tabs['overview'] = true; 
            }else{
                if(agency && agency !== ''){
                    this.last_agency = agency 
                }else{
                    this.last_agency = this.listAgencyActive_temp.length > 0 ? this.listAgencyActive_temp[0].agency_model : this.listAgencyActive(true).length > 0 ? this.listAgencyActive(true)[0].agency_model : ''
                }
                
                if(push && this.$route.query.agency !== agency){   
                    this.$router.replace({ query: Object.assign({}, this.$route.query, {tab : 'expert', agency : agency }) });
                }
            }   
        },
        /**
         * Get count results for a specific agency
         * @property {string} agency - agency to search
         *  */ 
        getCountAgency(agency){
            const findCount = this.countAgency.filter(item => item.agency_model == agency);
            if(findCount.length){
                let countagency = 0;
                for (let key in findCount) {
                  countagency += findCount[key].count;
                }
                return countagency;
            }
            return 0
        },
        /**
         * Get count filter results for a specific agency
         * @property {string} agency - agency to search
         *  */ 
        getCountFilter(agency){
            return this.countFilter ? this.countFilter[agency] : 0
        },
        cancel(param){
            this.active_filter = param
        },
        /**
         * Set a specific agency to active
         * @property {string} agency
         * @property {boolean} push - if reload is needed
         *  */ 
        setActiveAgency(Agency, push = true){
            if(this.$route.params.type != 'advanced_trs_ceesp' && this.getCountAgency(Agency)){
                console.log("setActiveAgency")
                this.$store.dispatch("result/setActiveAgency", Agency)
                const index_1 = this.listAgencyActive_temp.map(function(e) { return e.agency_model; }).indexOf(Agency);
                const index_2 = this.listAgency.map(function(e) { return e.agency_model; }).indexOf(Agency);
                if (index_1 == -1) {
                    this.listAgencyActive_temp.push(this.listAgency[index_2]);
                }
                this.count += this.getCountAgency(Agency)
                this.countAllFilter += this.getCountFilter(Agency)
                console.log(this.getCountFilter(Agency))
                this.setAgency(Agency, push)
            }
        },
        /**
         * Set a specific agency to unactive
         * @property {string} agency
         * @property {boolean} push - if reload is needed
         *  */
        setUnActiveAgency(Agency){
            console.log("setUnActiveAgency", Agency)
            this.$store.dispatch("result/setUnActiveAgency", Agency)
            if(Agency){
                const index = this.listAgencyActive_temp.map(function(e) { return e.agency_model; }).indexOf(Agency);
                // console.log(index, this.listAgencyActive_temp)
                if (index > -1) {
                    this.listAgencyActive_temp.splice(index, 1);
                }
            }
            
            this.count -= this.getCountAgency(Agency)
            this.countAllFilter -= this.getCountFilter(Agency)
            this.last_agency = ''
            
            if(this.filters){
                this.set_agency_by_filter()
            }
            
            // set agency
            if(this.last_agency){
                this.setAgency(this.last_agency)
            }
            else if(!this.last_agency && this.listAgencyActive_temp[0]){
                this.setAgency(this.listAgencyActive_temp[0].agency_model)
            }

            // when searchAgency is null
            if(this.searchAgency.length == 0){
                window.location.reload()
            }
        },
        /**
         * Set main agency based on filters (last)
         * If filters contain only communs, set agency to Overview
         * @property {string} agency - agency to set default
         * @property {boolean} push - if reload is needed
         *  */ 
        set_agency_by_filter(){
            let key = ''
            let val = this.filters.split('|')
            let onlyCommuns = false
            if(val !== ''){ 
                for(let i in val){
                    key = val[i].split('@')
                    if(key[0] && key[0] != 'communs' && this.searchAgency.includes(key[0])){
                        onlyCommuns = false
                        this.last_agency = key[0]
                        break
                    } else if(key[0] && key[0] === 'communs') {
                        onlyCommuns = true
                    }
                }
                return onlyCommuns
            }
            return false
        },
        /**
         * Set agencies sort
         *  */ 
        save_agency_sort(){
            if(this.change_agency_sort){
                this.change_agency_sort=false
                this.$store.dispatch('result/save_agency_sort', {agency : this.listAgencyActive_temp })
            }
        },

        /**
         * Reset graph (overview, expert and clinical_trials) and general filters and get original request
         * If we are on clinical trials tabs, we stay on clinical trials tab, if not, we redirect to overview
         *  */ 
        resetFilters() {
            for(let d in this.tabs){
                this.tabs[d] = false; 
            }

            if(this.filters !== "") {
                if(this.graph_filter)
                    this.reset_filter = true

                this.$store.dispatch("result/setFilters", "");
                this.$store.dispatch("advanced_result/clearStatePrix");
            }
            
            if(this.graph_filter){
                this.graph_filter = false

                if(this.$route.query.tab === 'essais_clinique'){
                    this.$router.replace({ query: Object.assign({}, this.$route.query, {tab : 'essais_clinique'}), params: {base64Search: this.original_base64} });
                    this.$store.dispatch(`essais_clinique/result_essais_clinique`,  {...this.$route.params, ...this.$route.query, main_page: 'Simple', order_by: 'nct_id', graph_filter: false, base64Search: this.original_base64})
                } else{
                    this.$router.replace({ query: Object.assign({}, this.$route.query, {tab : 'overview', agency : 'Overview' }), params: {base64Search: this.original_base64} });
                    this.setAgency('Overview')
                    this.request_result_agency({graph_filter:false, base64Search: this.original_base64, order_by:'decision_date_num', sort:'DESC'})
                }
            }
        },

        /**
         * Sort (inactive) agency count DESC
         */
        sortByCountResults(agencies) {
            const that = this
            return agencies.sort(function(a, b) {
                var keyA = that.getCountAgency(a.agency_model),
                    keyB = that.getCountAgency(b.agency_model);

                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });
        },
        /**
         * Set graph_filter data from component TableEssaisClinique event
         */
        graph_filter_callback(param = {}) {
            if('graph_filter' in param){
                this.graph_filter = param.graph_filter
                if(!this.original_base64){
                    let paramObj = {...this.$route.params, ...param}
                    this.original_base64 = paramObj['base64Search']
                }
            }
        }
    },
    created() {        
        if(this.$route.query.zoom){
            window.addEventListener("resize", this.eventHandlerResize);
        }
    },
    unmounted () {
        document.body.classList.remove('body-bg-gray');
        this.$store.dispatch("result/clearState")         
        this.$store.dispatch("report/clearState") 
        this.$store.dispatch("advanced_result/clearStatePrix")        
        this.$store.dispatch("essais_clinique/clearState")
        this.$store.dispatch("detail/clearState")
        this.$store.dispatch("header/setOpenBoxSearch", false) 
        if(this.$route.query.zoom){
            window.removeEventListener("resize", this.eventHandlerResize);
        }
    }
}
</script>
 
<style scoped>  
.modal-backdrop{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    background-color: #000;
    opacity: .5;
}
.flag-active{
    border-bottom: 1px solid #e6e5e8;
}
.open-bar-legend.open-bar {
    z-index: 3;
}
.content-onglets ul li{
    padding: 5px 10px 0 5px;
    font-size: 12px;
}

@media only screen and (max-width: 862px){
    .main-body{
        position: relative;
        
    }
}

/* @media only screen and (min-width: 863px){
    .main-body{
        position: fixed;
    }
} */

</style>
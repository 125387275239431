<template>
    <div class="box_general_header">
        <form @submit.prevent="handleSubmit"> 
        <!-- <div id="alert-form" class="alert alert-danger" style=" display: none;clear: both;margin: 6px;"></div> -->
        <div class="col-md-20">
            <div class="detail-search">
                <div class="title-box-search">{{ datas.name.title }}</div>
                <autocomplete  :items="autocomplete_name" inputname="name"  @onReturn="onReturn"  :placeholder="datas.name.placeholder"  :clear="datas.name.clear"  :classinputname="'form-control'"  />
                <div class="save-search">
                    <ul v-if="datas.name.items.length">
                        <li>
                            <label>
                                <input type="checkbox" checked="checked" @click="checkAll('name')" v-model="datas.name.check_all" />
                                <span class="check-therapeutic"></span>
                                <div>Select all / Unselect all</div>
                            </label>
                        </li>
                        <li v-for="(list, index) in datas.name.items" :key="index">
                            <label>
                                <input type="checkbox" checked="checked" :value="list" v-model="datas.name.data" name="name" @change="onChangeCheckbox('name')" />
                                <span class="check-therapeutic"></span>
                                <div>{{ list }}</div>
                            </label>
                        </li>
                    </ul>                    
                </div>
            </div>
        </div>
    
        <div class="col-md-20">
            <div class="detail-search">
                <div class="title-box-search">{{ datas.therapeutic.title }}</div>
                <autocomplete :items="autocomplete_therapeutic"  inputname="therapeutic" @onReturn="onReturn"  :clear="datas.therapeutic.clear" :placeholder="datas.therapeutic.placeholder"  :classinputname="'form-control'"  />
                <div class="save-search">
                    <ul v-if="datas.therapeutic.items.length">
                        <li>
                            <label>
                                <input type="checkbox" checked="checked" @click="checkAll('therapeutic')" v-model="datas.therapeutic.check_all" />
                                <span class="check-therapeutic"></span>
                                <div>Select all / Unselect all</div>
                            </label>
                        </li>
                        <li v-for="(list, index) in datas.therapeutic.items" :key="index">
                            <label>
                                <input  type="checkbox" checked="checked"  :value="list" v-model="datas.therapeutic.data" name="therapeutic" @change="onChangeCheckbox('therapeutic')"  />
                                <span class="check-therapeutic"></span>
                                <div>{{ list }}</div>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>       
        </div>
        <template v-if="type=='inter'">
            <div class="col-md-20">
                <div class="detail-search">
                    <div class="title-box-search">Agences</div>
                    <div class="save-search none-input">
                        <ul>
                           
                        <li>
                            <label>
                                <input type="checkbox" checked="checked" @click="checkAll('agency')" v-model="select_all_agency" />
                                <span class="check-therapeutic"></span>
                                <div>Select all / Unselect all</div>
                            </label>
                        </li>
                            <span v-for="(item,key) in groupAgency" :key="key" >
                                <label  v-if="Object.keys(item).length > 0" class="label-group_country">{{key}}</label>   

                                <li v-for="(item_data, key_data) in item" :key="'agency-'+key_data">
                                    <label>
                                        <input type="checkbox" :value="key_data" v-model="datas.agency.country" name="agency" @click="activeAgency(key_data, item_data.agency)" :disabled="disabledDrug">
                                        <span class="check-therapeutic"></span>
                                        <img :alt="key_data" :src="require('@/assets/images/flag/new/'+item_data.icon_flags+'.svg')" width="20" height="20" style="margin: 5px;" />
                                        <span style="margin-left: 0px;">{{key_data}}</span>
                                    </label>
                                </li>  
                            </span>
                        </ul>
                    </div>
                    
                </div>
            </div>
            <div class="col-md-20">
                <div class="detail-search haft-box">
                    <div class="row">
                        <div class="col-6 pr-1">
                            <p class="title-box-search">{{datas.date_start.title}}</p>
                            <div class="calender-header">
                                 <datepicker 
                                    v-model="datas.date_start.data" 
                                    :locale="language"
                                    inputFormat="MM/yyyy" 
                                    minimumView="month"
                                    class="form-control" 
                                    :placeholder="$t('MM/YYYY')"
                                    :clearable="true" name="date_start" autocomplete="off"
                                    :disabled="disabledDrug" 
                                    >  
                                    <template v-slot:clear>
                                        <div class="date_clearable" @click="datas.date_start.data = null"><em>x</em></div>
                                    </template>
                                </datepicker>                                
                            </div>
                        </div>
                        <div class="col-6 pl-1">
                            <p class="title-box-search">{{datas.date_end.title}}</p>
                            <div class="calender-header"> 
                                 <datepicker 
                                    v-model="datas.date_end.data" 
                                    :locale="language"
                                    inputFormat="MM/yyyy" 
                                    minimumView="month"
                                    class="form-control" 
                                    :placeholder="$t('MM/YYYY')"
                                    :clearable="true" name="date_end" autocomplete="off"
                                    :disabled="disabledDrug"
                                    >  
                                    <template v-slot:clear>
                                        <div class="date_clearable" @click="datas.date_end.data = null"><em>x</em></div>
                                    </template>
                                </datepicker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-20">
                <div class="detail-search haft-box relative">               
                    <div class="save-search status"> 
                        <ul>
                             <li v-for="(list, index) in datas.en_cours.items" :key="'DrugStatus-'+index">
                                <label>
                                    <input type="checkbox" :value="list.value" class="result_v2" v-model="datas.en_cours.data"  name="en_cours"  :disabled="disabledDrug"/>
                                    <span class="check-therapeutic"></span>
                                    <div>{{ list.display2 }}</div>
                                </label>
                            </li>
                            <li v-for="(list, index) in datas.mk_orphelin.items" :key="'mk-'+index">
                                <label>
                                    <input  type="checkbox" :value="list.value" class="result_v2" v-model="datas.mk_orphelin.data"  name="mk_orphelin" :disabled="disabledDrug" />
                                    <span class="check-therapeutic"></span>
                                    <div>{{ list.display }}</div>
                                </label>                            
                            </li> 
                            <li v-for="(list, index) in datas.disease.items" :key="'disease-'+index">
                            <label>
                                <input  type="checkbox" :value="list.value" class="result_v2" v-model="datas.disease.data"  name="disease" :disabled="disabledDrug" />
                                <span class="check-therapeutic"></span>
                                <div>{{ list.display }}</div>
                            </label>                            
                        </li>                             
                        </ul>
                    </div>
                    <div class="area-overlay" v-show="disabledDrug"></div>                    
                </div> 
            </div>
        </template>
        <template v-else>
        <div class="col-md-20">
            <div class="detail-search haft-box relative">               
                <div class="save-search status"> 
                    <ul>
                        <li v-for="(list, index) in datas.AAP.items" :key="'AAP-'+index">
                            <label>
                                <input type="checkbox" :value="list.value" class="result_v2" v-model="datas.AAP.data"  name="AAP"  :disabled="disabledDrug"/>
                                <span class="check-therapeutic"></span>
                                <div>{{ list.display }}</div>
                            </label>
                        </li>
                        <li v-for="(list, index) in datas.mk_orphelin.items" :key="'mk-'+index">
                            <label>
                                <input  type="checkbox" :value="list.value" class="result_v2" v-model="datas.mk_orphelin.data"  name="mk_orphelin" :disabled="disabledDrug" />
                                <span class="check-therapeutic"></span>
                                <div>{{ list.display }}</div>
                            </label>                            
                        </li> 
                        <li v-for="(list, index) in datas.disease.items" :key="'disease-'+index">
                            <label>
                                <input  type="checkbox" :value="list.value" class="result_v2" v-model="datas.disease.data"  name="disease" :disabled="disabledDrug" />
                                <span class="check-therapeutic"></span>
                                <div>{{ list.display }}</div>
                            </label>                            
                        </li>                        
                    </ul>
                </div>
                <div class="area-overlay" v-show="disabledDrug"></div>                    
            </div> 
            

            <div class="detail-search haft-box">
                    <div class="row">
                        <div class="col-6 pr-1">
                            <p class="title-box-search">{{datas.date_start.title}}</p>
                            <div class="calender-header">                                
                                <datepicker 
                                    v-model="datas.date_start.data" 
                                    :locale="language"
                                    inputFormat="MM/yyyy" 
                                    minimumView="month"
                                    class="input-group" 
                                    :placeholder="$t('MM/YYYY')" 
                                    :clearable="true" 
                                    style="margin-bottom: 0px; width:100%;" 
                                    >  
                                    <template v-slot:clear>
                                        <div class="date_clearable" @click="datas.date_start.data = null"><em>x</em></div>
                                    </template>
                                </datepicker>                                
                            </div>
                        </div>
                        <div class="col-6 pl-1">
                            <p class="title-box-search">{{datas.date_end.title}}</p>
                            <div class="calender-header">
                                <datepicker 
                                    v-model="datas.date_end.data" 
                                    :locale="language"
                                    inputFormat="MM/yyyy" 
                                    minimumView="month"
                                    class="input-group" 
                                    :placeholder="$t('MM/YYYY')" 
                                    :clearable="true" 
                                    style="margin-bottom: 0px; width:100%;" 
                                    >  
                                    <template v-slot:clear>
                                        <div class="date_clearable" @click="datas.date_end.data = null"><em>x</em></div>
                                    </template>
                                </datepicker>                                
                            </div>
                        </div>
                    </div>
            </div>
            <div class="relative">
                <div class="detail-search">
                    <div class="title-box-search"></div>
                    <div class="save-search">
                        <ul>
                            <li v-for="(list, index) in datas.en_cours.items" :key="index">
                                <label>
                                    <input type="checkbox"  :value="list.value"  class="result_v2" v-model="datas.en_cours.data" name="en_cours" :disabled="disabledDrug" />  
                                    <span class="check-therapeutic"></span>
                                    <div>{{ list.display }}</div>
                                </label>
                                
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="area-overlay" v-show="disabledDrug"></div>
            </div>
            <div class="relative">
                <div class="detail-search">                    
                    <div class="title-box-search">{{datas.type_demande.title}}</div>      
                    <div class="save-search">
                        <ul>
                            <li v-for="(list, index) in datas.type_demande.items" :key="index">
                                <label>
                                    <input type="checkbox" :value="list.value"  class="result_v2" v-model="datas.type_demande.data" name="type_demande" :disabled="disabledDrug" />                           
                                    <span class="check-therapeutic"></span>
                                    <div>{{ list.display }}</div>
                                </label>                           
                            </li>
                        </ul>
                    </div>                        
                </div>
                <div class="area-overlay" v-show="disabledDrug"></div>
            </div>
         </div>
        <div class="col-md-20 mb-2">
            <div class="relative">
                <div class="detail-search">
                    <div class="title-box-search">{{datas.smr.title}}</div>
                    <div class="save-search">
                        <ul>
                            <li v-for="(list, index) in datas.smr.items" :key="index">
                                <label>
                                    <input type="checkbox" :value="list.value" class="result_v2" v-model="datas.smr.data"  name="smr" :disabled="disabledDrug" />                              
                                    <span class="check-therapeutic"></span>
                                    <div>{{ list.display }}</div>
                                </label>                                
                            </li>
                        </ul>
                    </div>
                </div>            
                <div class="detail-search">
                    <div class="title-box-search">{{datas.asmr_obtenue.title}}</div>
                    <div class="save-search">
                        <ul>
                            <li v-for="(list, index) in datas.asmr_obtenue.items" :key="index">
                                <label>
                                    <input type="checkbox" :value="list.value" class="result_v2" v-model="datas.asmr_obtenue.data" name="asmr_obtenue" :disabled="disabledDrug">
                                    <span class="check-therapeutic"></span>
                                    <div>{{ list.display }}</div>
                                </label>
                                
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="area-overlay" v-show="disabledDrug"></div>                
            </div>
        </div>
        <div class="col-md-20 mb-2 relative">           
            <div class="detail-search">
                <div class="title-box-search">{{datas.ceesp.title}}</div>
                <div class="save-search">
                    <ul>
                        <li v-for="(list, index) in datas.ceesp.items" :key="index" >
                            <label> 
                                <input  type="checkbox"  :value="list.value" class="result_v2" v-model="datas.ceesp.data" name="ceesp" :disabled="disabledDrug"/>
                                <span class="check-therapeutic"></span>
                                <div>{{ list.display }}</div>
                            </label>
                        </li>
                    </ul>                        
                </div>
            </div>
            <div class="area-overlay" v-show="disabledDrug"></div>
        </div>
        <div class="col-md-20 mb-2 relative">
            <div class="detail-search">
                <div class="title-box-search">{{datas.prix.title}}</div>
                <div class="save-search">
                    <ul>
                        <li>
                            <label>
                            <input  type="checkbox" checked="checked" @click="checkAll('prix')" v-model="datas.prix.check_all"  name="prix" :disabled="disabledPrix" />
                            <span class="check-therapeutic"></span>
                            <div>{{$t('simple.Price - Inscription')}}</div> 
                            </label>
                        </li>
                        <li v-for="(list, index) in datas.prix.items" :key="index" style="margin-left:20px">
                            <label>
                                <input type="checkbox"  :value="list.value"  class="result_v2"  v-model="datas.prix.data" name="prix" :disabled="disabledPrix" @change="onChangeCheckbox('prix')"/>
                                <span class="check-therapeutic"></span>
                                <div>{{ list.display }}</div>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>   
            <div class="detail-search">
                <div class="title-box-search">{{ datas.cip.title }}</div>
                <input type="text" class="form-control"   :placeholder="datas.cip.placeholder" @focusout="cipValue"  v-model.lazy="datas.cip.tempdata" name="cip" :disabled="disabledPrix" />               
               
            </div>
            <div class="area-overlay" v-show="disabledPrix"></div>
        </div>
        </template>     
             
        <div class="button-header">
            <router-link :to="homePage">
                <button class="btn-border-orange" style="margin:5px 15px; width: 200px;">{{$t('Make a new search')}}</button>
            </router-link>
            <button type="submit" class="btn-orange" style="margin:5px 15px; width: 200px;">{{$t('Recherche')}}</button>
            <div class="save-my-search" style="display: inline-block;width: 200px;" data-bs-toggle="modal" data-bs-target="#modal-save-keyword">
                <div class="box-save-search m-0" >
                    {{$t('Mémoriser la requête')}}                 
                </div>
            </div>
        </div>
        </form>
           
    </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import Autocomplete from "@/components/elements/autocomplete.vue";
import { Base64 } from 'js-base64';
import { homePage, isValidDate } from "@/utils";
import moment from 'moment'
import { enGB, fr, de } from 'date-fns/locale'

export default {
    name: "BoxSearch",
    components: {
        Datepicker,
        Autocomplete,
    },
    data: function() {
        return {           
            languages : {enGB, fr, de },
            // modalShow : false,
            inputMySearch : "",
            datas: {            
                name: {
                    title: this.$t("simple.name"),
                    clear: true,
                    placeholder: this.$t("Write here"),
                    items: [],
                    data: [],
                    check_all: true,
                    main : 'public'

                },
                therapeutic: {
                    title: this.$t("simple.therapeutic"),
                    clear: true,
                    placeholder:this.$t("Write here"),
                    items: [],
                    data: [],
                    check_all: true,
                    main : 'public'
                },

            
                AAP: {
                    title: "",
                    style: "inline",
                    items: [{ value: "1", display:"AAP"}],
                    data: [],
                    main : 'Acces_precoce'
                },
                mk_orphelin: {
                    title: "",
                    style: "inline",
                    items: [{ value: "1", display: this.$t("simple.Orphan drug") }],
                    data: [],
                    main : 'public'
                },
                disease: {
                    title: '',
                    items : [
                        {value : "1", display : this.$t('Rare disease') }, 
                    ],                
                    data : [],
                    main : 'public'
                },
                date_start: {
                    title: this.$t('date_start'), 
                    clear: true,
                    style : 'inline',  
                    data: null,
                    main : 'public'
                },
                date_end: {
                    title: this.$t('date_end'), 
                    clear: true,
                    style : 'inline',  
                    data: null,
                    main : 'public'
                },
                en_cours: {
                    title: "",
                    style: "inine",
                    items: [{ value: "1", display: this.$t("Ongoing assessment"), display2 : this.$t('simple.In development') }],
                    data: [],
                    main : 'public'
                },
                type_demande: {
                    title: this.$t("simple.type_demande.title"),
                    style: "",
                    items: [
                        { value: "INS", display: this.$t("simple.type_demande.INS") },
                        { value: "EIT", display: this.$t("simple.type_demande.EIT") }
                    ],
                    data: [],
                    main : 'Transparence'

                },
                smr: {
                    title: this.$t("simple.smr.title"),
                    style: "",
                    items: [
                        { value: "important", display: this.$t("simple.smr.important") },
                        { value: "modéré", display: this.$t("simple.smr.modere") },
                        { value: "faible", display: this.$t("simple.smr.faible") },
                        {value : "insuffisant", display : this.$t('simple.smr.insuffisant')}, 

                    ],
                    data: [],
                    main : 'Transparence'

                },
                asmr_obtenue: {
                    title: this.$t("simple.asmr"),
                    style: "",
                    items: [
                        { value: "I", display: "I" },
                        { value: "II", display: "II" },
                        { value: "III", display: "III" },
                        { value: "IV", display: "IV" },
                        { value: "V", display: "V" }
                    ],
                    data: [],
                    main : 'Transparence'

                },
                ceesp: {
                    title: "CEESP",
                    style : "",
                    items : [
                        {value : "avis", display :  this.$t('simple.Opinion on efficiency')},
                        {value : "publication", display : this.$t("simple.College publication")}, 
                    ],
                    data : [],
                    main : 'Ceesp'
                },
                prix: {
                    title:this.$t('simple.PRICE') ,
                    style: "",
                    items: [
                        {value : "ss", display : "SS"},
                        {value : "coll", display : "Coll"},
                        {value : "t2a", display : "T2A"},
                        {value : "retro", display : this.$t("simple.Retrocession")},                        
                        {value : "atu_prix", display : this.$t("simple.ATU Price")},
                    ],
                    data: [],
                    check_all: false,
                    main : 'Prix'
                },
                cip: {
                    title: "CIP7/CIP13",
                    placeholder: "CIP7/CIP13",
                    data: "",
                    tempdata: "",
                    main : 'Prix'
                },
                
                agency : {
                    title : "", 
                    style : 'inline', 
                    data : [],
                    main : 'public',
                    // check_all: true,
                    country : []
                },
            },
            lang : localStorage.getItem('language'),
            disabledPrix : false,
            disabledDrug : false,
            type : this.$route.params.type

        };
    },
    mounted() {
        this.loadAutocomplete();
        this.initial();
        // this.initAgency();  
    },  
    updated() {  
        console.log('box search updated...')       

        //check prix and other to disable input 
        if(this.datas.prix.data.length > 0 || this.datas.cip.data !== "" ){            
            this.disabledDrug = true
            this.disabledPrix = false
        } else if(this.datas.agency.data.length > 0){
            let tmp_state = true
            for (let key in this.groupAgency) {
                for (let item_agency in this.groupAgency[key]) {
                    if(!this.datas.agency.country.includes(item_agency)){
                        tmp_state = false
                    }
                }
            }
            this.datas.agency.check_all = tmp_state
            this.disabledPrix = false            
            this.disabledDrug = false
        }else if(this.datas.AAP.data.length > 0 || this.datas.mk_orphelin.data.length > 0 ||
            this.datas.disease.data.length > 0  || this.datas.date_start.data || this.datas.date_end.data ||
            this.datas.en_cours.data.length > 0 || this.datas.type_demande.data.length > 0 ||
            this.datas.smr.data.length > 0 || this.datas.asmr_obtenue.data.length > 0 ||
            this.datas.ceesp.data.length > 0 ){            
            this.disabledPrix = true
            this.disabledDrug = false
        }else{
            this.disabledPrix = false            
            this.disabledDrug = false
        }


    },
    methods: {
        onChangeCheckbox(keyData){
            if(keyData == 'agency'){
                if ( this.datas[keyData].data.length === this.accessAgency.length) {
                    this.datas[keyData].check_all = true;
                } else {
                    this.datas[keyData].check_all = false;
                }
            }
            else{
                if ( this.datas[keyData].data.length === this.datas[keyData].items.length) {
                    this.datas[keyData].check_all = true;
                } else {
                    this.datas[keyData].check_all = false;
                }
            }
        },
        initial(){
            const base64Search = Base64.decode(this.$route.params.base64Search);
            const arrSearch = base64Search.split('|');
            //let keyword = {}
            for(const temp of arrSearch){
                const temp2 = temp.split('=');
                if(temp2[0] && temp2[1]){
                    if(temp2[0].indexOf("@") !== -1){                    
                        const temp3 = temp2[0].split('@');
                        if(temp3[1] in this.datas){ 
                            this.datas[temp3[1]].data = temp2[1].split('#')
                        }
                    }else{   
                        if(temp2[0] in this.datas){ 
                            if(temp2[0] == 'date_start' || temp2[0] == 'date_end') {
                                let new_date = temp2[1].split('/') 
                                this.datas[temp2[0]].data = moment(new_date[0]+"/01/"+new_date[1]).toDate()                                
                            }else{
                                this.datas[temp2[0]].data = temp2[1].split('#') 
                                if(temp2[0] == 'therapeutic' || temp2[0] == 'name')                        
                                    this.datas[temp2[0]].items = temp2[1].split('#') 
                            }
                            
                        }                    
                    }
                }
            }
        }, 
        activeAgency(country, agency){
            for (let values_key in agency) {
                const index = this.datas.agency.data.indexOf(agency[values_key]);
                if (index > -1) {
                    this.datas.agency.data.splice(index, 1);
                } else if(agency[values_key]){
                    this.datas.agency.data.push(agency[values_key]);
                }
            }
            const index = this.datas.agency.country.indexOf(country);
            if (index > -1) {
                this.datas.agency.country.splice(index, 1);
            } else if(country){
                this.datas.agency.country.push(country);
            }
        },
         saveMySearch(){
            if(this.inputMySearch === ""){
                alert('name field cannot be empty.');
                return false
            }
            var keyword_decode = atob(this.$route.params.base64Search);
            var keyword_arr = keyword_decode.split("|");
            var keyword_json = {};

            var keyword_arr_value = [];
            for (var i = 0; i < keyword_arr.length; i++){
                // console.log(keyword_arr[i]);
                if(keyword_arr[i] != ""){
                    keyword_arr_value[i] = keyword_arr[i].split("=");
                    // console.log(keyword_arr_value[i]);
                    keyword_json[keyword_arr_value[i][0]] = keyword_arr_value[i][1];
                }
            }
            console.log(keyword_json);
            this.$store.dispatch("users/saveKeyword", { name : this.inputMySearch, keyword : this.$route.params.base64Search, type : this.type, link : this.$route.path, keyword_decode : keyword_json})    
        },
        onShowModal(){
            console.log('onShowModal')
            this.$store.dispatch("users/setSaveKeywordSuccess", false)
        },
        cipValue(){
            console.log(this.datas.cip.tempdata.length);
            if(this.datas.cip.tempdata.length == 0){
                this.datas.cip.title = ''
                this.datas.cip.data = ''
            }
            else if(this.datas.cip.tempdata.length == 7){
                this.datas.cip.title = 'CIP7'
                this.datas.cip.data = this.datas.cip.tempdata
            }
            else if(this.datas.cip.tempdata.length == 13){
                this.datas.cip.title = 'CIP13'
                this.datas.cip.data = this.datas.cip.tempdata
            }
            else{
                this.datas.cip.tempdata = ''
                this.datas.cip.data = ''
                alert("Veuillez saisir un CIP7 ou un CIP13.")
            }
        },
        isValidDate,
        handleSubmit(){           
            let strResultForm = "" 
            for (const [key, items] of Object.entries(this.datas)) {
                if(items.main == "public"){
                    if(Array.isArray(items.country) && items.country.length > 0 && key == 'agency'){  
                        let tmp_data = ""
                        for (let key2 in this.groupAgency) {
                            for (let item_agency in this.groupAgency[key2]) {
                                console.log(items.country)
                                for (let item_datas in items.country) {
                                    if(item_agency == items.country[item_datas]){
                                        if(tmp_data != ''){
                                            tmp_data = tmp_data + '#'
                                        }
                                        tmp_data = tmp_data  + `${this.groupAgency[key2][item_agency].agency.join('#')}` 
                                    }
                                }
                            }
                        }
                        strResultForm += `${key}=${tmp_data}|` 
                    }else if(Array.isArray(items.data) && items.data.length > 0){                        
                        strResultForm += `${key}=${items.data.join('#')}|` 
                    }else if(this.isValidDate(items.data)){
                        strResultForm += `${key}=${moment(items.data).format("MM/YYYY")}|`
                    }else if(typeof items.data == 'string' && items.data !== ''){
                        strResultForm += `${key}=${items.data}|`
                    }
                }else{
                    if(Array.isArray(items.data) && items.data.length > 0){
                        strResultForm += `${items.main}@${key}=${items.data.join('#')}|`
                    }else if(typeof items.data == 'string' && items.data !== ''){
                        strResultForm += `${items.main}@${key}=${items.data}|`
                    }
                }
            }           
            // console.log(strResultForm);            
            if(strResultForm === ""){
                alert(this.$t('The field cannot be empty'));
                return false
            }

            let atu, transparence, ceesp, prix; 
            strResultForm.indexOf("Atu@") > -1 ? atu = true : atu = false
            strResultForm.indexOf("Transparence@") > -1 ? transparence = true : transparence = false
            strResultForm.indexOf("Ceesp@") > -1 ? ceesp = true : ceesp = false
            strResultForm.indexOf("Prix@") > -1 ? prix = true : prix = false
                
            if(prix){
                this.$router.push(`/advanced_result_prix/${Base64.encode(strResultForm)}`);
            }else{
                if((transparence || atu) && ceesp)
                    strResultForm += "main_agency=Transparence_Ceesp|"
                else if(transparence || atu)
                    strResultForm += "main_agency=Transparence|"
                else if(ceesp)
                    strResultForm += "main_agency=Ceesp|"
                this.$router.push(`/result/${Base64.encode(strResultForm).replace('/\+/g', '-').replace('/\//g', '_')}/${this.type}`); // eslint-disable-line
                setTimeout(() => {  this.$router.go(); }, 200);
            }
            
        },
        loadAutocomplete() {
            const keyLoadAutoComplete = [];
            if (this.$store.getters["autocomplete/name"].length === 0) {
                keyLoadAutoComplete.push("name");
            }
            if (this.$store.getters["autocomplete/therapeutic"].length === 0) {
                keyLoadAutoComplete.push("therapeutic");
            }
            if(keyLoadAutoComplete.length > 0){
                this.$store.dispatch("autocomplete/loadAutocomplete", keyLoadAutoComplete)
            }
        },
        onReturn(value) {
            if (this.datas[value.key].items.indexOf(value.value) === -1) {
                // console.log('onreturn ..push')
                this.datas[value.key].items.push(value.value);
                this.datas[value.key].data.push(value.value);
                // this.datas[value.key].result.push(value.value);
            }
        },
        checkAll(keyData) {      
           
            this.datas[keyData].data = [];
            if (!this.datas[keyData].check_all) {
                for (let k in this.datas[keyData].items) {
                    if (keyData=='prix'){
                        this.datas[keyData].data.push(this.datas[keyData].items[k].value);
                    }else{
                        this.datas[keyData].data.push(this.datas[keyData].items[k]);
                    }
                }
            }      
        }
    },
    computed: {
        homePage,
        accessAgency(){
            return this.$store.getters['result/access_agency']
        },
        saveKeywordSuccess() {
            return this.$store.getters["users/saveKeywordSuccess"];
        },
        loadingSaveKeyword() {
            return this.$store.getters["users/load"];
        },
        autocomplete_name() {
            return this.$store.getters["autocomplete/name"];
        },
        autocomplete_therapeutic() {
            return this.$store.getters["autocomplete/therapeutic"];
        },    
        groupAgency(){
            return this.$store.getters['agency/groupAgency'] 
        },
        select_all_agency:{
            get: function () {
                let temp = [];
                Object.values(this.groupAgency).forEach(agency=>{
                    Object.keys(agency).forEach(function (val) {
                        temp.push(val);
                    });
                });
                return this.datas.agency.data.length == temp.length ? true : false;
            },
            set: function (value) {
                let selected = [];

                if (value) {
                    Object.values(this.groupAgency).forEach(agency=>{
                        Object.keys(agency).forEach(function (val) {
                            selected.push(val);
                        });
                    });
                }
                this.datas.agency.country = selected;
            }
        },
        language(){
            return this.$i18n.locale !== 'en' ? this.languages[this.$i18n.locale] : this.languages[enGB]
        }
        
    },
    watch : {
        groupAgency(){
            for(let data of this.datas.agency.data){
                for (let key in this.groupAgency) {
                    for (let item_agency in this.groupAgency[key]) {
                        if(this.groupAgency[key][item_agency].agency.includes(data)){
                            if(!this.datas.agency.country.includes(item_agency)){
                                this.datas.agency.country.push(item_agency)
                                break
                            }
                        }
                    }
                }
            }
        }
    }
    };
</script>
<style scoped>
    .save-search {
    text-transform: none;
    }  
    .icon-calendar-orange{
    /* mx-icon-calendar { */
        width: 25px;
        height: 25px;
        background-image: url("/images/icon-calendar.svg");
        background-repeat: no-repeat;
        display: block;
    }
</style>

<template>
  <div class="autocomplete">
    <input
      type="text"
      @input="onChange"
      v-model="search"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      :placeholder="placeholder"
      :class="'form-control ' + classinputname"
      :disabled="disabled"
      v-on:blur="didYouMean"
      
  
    />
    <slot></slot>
    <ul id="autocomplete-results" v-if="isOpen" class="autocomplete-results sss" :style="style_autocomplete">
      <li class="loading" v-if="isLoading">
        Loading results...
      </li>
      <li
        v-else
        v-for="(val, i) in results"
        :key="i"
        @click="setResult(val)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ val }}
      </li>
    </ul>
  </div>
</template>
<script>
import { convertToAscii } from '@/utils';
export default {
  name: "autocomplete",

  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled:{      
      type: Boolean,
      required: false,
      default: false,
    },
    inputname: {
      required: true,
      default: "",
    },
    clear:{
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder:{
      type: String,
      default: "",
    },
    style_autocomplete:{
      type: String,
      default:""
    },
    methodfor:{
      type: String,
      default: "simple"
    },
    classinputname:{
      type: String,
      default: ""
    },
     empty:{
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      results: [],
      search: "",
      isLoading: false,
      arrowCounter: -1,
    };
  },

  methods: {
    onChange() {
      console.log('onChange auto')
      // Is the data given by an outside ajax request?
      if (this.isAsync) {
        this.isLoading = true;
      } else {
        if (this.search !== "") {
          // Let's  our flat array
          this.filterResults();
          if(this.results.length > 0){
            this.isOpen = true;
          }
          
        } else {
          this.isOpen = false;
        }
      }
    },
    didYouMean(){
      this.$store.dispatch("did_you_mean/searchDidYouMean",  { name : this.search} )
    },
    returnValue(){
      // Let's warn the parent that a change was made
      if(this.methodfor == 'advance'){
        this.$emit("onAutocomplete", { key: this.inputname, value: this.search });
      }else{
        this.$emit("onReturn", { key: this.inputname, value: this.search });
      }
      if(this.clear){
         this.search = "" 
      }
    },
    filterResults() {
      // first uncapitalize all the things
      this.results = this.items.filter((item) => {
        if(this.inputname === 'atc'){
          return convertToAscii(item).toLowerCase().startsWith(this.search.toLowerCase());
        } else{
          return convertToAscii(item).toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        }
      });
    },
    setResult(result) {
      console.log(result + ' **********************');
      if(result !== undefined){
        console.log(result + ' ****sdfssf***********');
        this.search = result;
        this.isOpen = false;      
        this.returnValue()
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {   
      if(this.arrowCounter !== -1){
        this.search = this.results[this.arrowCounter];
      }      
      this.isOpen = false;
      this.arrowCounter = -1; 
      if (this.search !== "") {
        this.returnValue()
      } else if (this.empty){
          this.returnValue();
      }
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target) && this.isOpen) {
        this.isOpen = false;
        this.arrowCounter = -1;
        if (this.search !== "") {
          this.returnValue()
        }else if (this.empty) {
          this.returnValue();
        }
      }
    },
  },
  watch: {
    items: function(val, oldValue) {
      // actually compare them
      if (val.length !== oldValue.length) {
        this.results = val;
        this.isLoading = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #c5c5c5;
  max-height: 150px;
  overflow: auto;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 7px;
  border-radius: 17px;
  background: #fff;
  position: absolute;
  z-index: 51;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 10px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background: #ededed;
  color: #000;
}

.form-cs{
  background-color: #fff;
}

.form-tag p {
  margin: 0px 0px 0px 15px;
}
</style>

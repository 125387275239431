<template>
    <div>
        <div :class="'box-filter active'">
            <div class="filter-inner apply" @click="apply" >
                <img :src="require(`@/assets/dist/2019/images/icon/btn-ok${isUserFrench ? '-fr' : ''}.svg`)" alt="">
                <p>{{$t('Apply')}}</p>
            </div>
            <div class="filter-inner cancel" @click="cancel">
                <img :src="require(`@/assets/dist/2019/images/icon/ic-close${isUserFrench ? '-fr' : ''}.svg`)" alt="">
                <p>{{$t('Cancel')}}</p>
            </div>
        </div>
        <ul>
           <template v-for="(items, key) in filter_sort"> 
                <template v-if="filters[items]">
                    <li :class="filters[items]['show'] ? 'main-menu-filter arrow-active' : ''" :id="'filter-'+filters[items]['title']" :data-type="filters[items]['title']" :key="key" v-if="active_filter(filters[items]['title']) || ['Communs', 'Common'].includes(filters[items]['title'])">
                        <div class="title-filter" :style="item_data_active(filters[items]) ? 'color:#ff6300;' : '' " @click="toggle_class(items)">
                            {{ filters[items]['title'] ? $t(filters[items]['title']) : ""}}
                            <em class="fa fa-angle-right" aria-hidden="true"></em>
                        </div>
                        <ul class="sub-menu-filter">
                            <li v-for="(sub_items, key) in filters[items]['items']" :key="key">
                                <a >{{sub_items.sub_title}}</a>
                                <ul class="list-checkbox">
                                    <li :class="sub_items.style" v-for="(option, key) in sub_items.options" :key="key">
                                        <div class="box-check box-check-text box-check-xs w-100" >
                                            <label v-if="option.value == 'text'">
                                                <div class="txt-check text-gray w-100" style=" padding: 3px;">
                                                    <input type="text" id="communs_treatment_specificity" class="form-control form-cs font-text" v-model="filters[items]['data'][option.name]" :placeholder="$t('Write here...')">
                                                </div>
                                            </label>
                                            <label v-else-if="['communs_type_ma', 'communs_particularity'].includes(option.name)" class="label_include-exclude">
                                                <div class="txt-check text-gray">
                                                    {{option.display}}
                                                </div>
                                                <div class="container_include-exclude">
                                                   <label :for="option.value1">
                                                       <span 
                                                            :class="(filters[items]['data'][option.name].includes(option.value1)) ? 'active' : ''" 
                                                            @click="handleClickCSS" 
                                                            :data-attribute="'class_'+option.value1" 
                                                            :data-attribute-class="'include_'+option.name" 
                                                            :data-attribute-name="option.name" 
                                                            :data-attribute-value="option.value1" 
                                                            :data-attribute-items="items">
                                                            Include 
                                                        </span> |
                                                    </label>
                                                    <label :for="option.value2">
                                                        <span 
                                                            :class="(filters[items]['data'][option.name].includes(option.value2)) ? 'active' : ''" 
                                                            @click="handleClickCSS" 
                                                            :data-attribute="'class_'+option.value1" 
                                                            :data-attribute-class="'exclude_'+option.name" 
                                                            :data-attribute-name="option.name" 
                                                            :data-attribute-value="option.value2" 
                                                            :data-attribute-items="items">
                                                            Exclude 
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                            <label v-else>
                                                <input type="checkbox" :value="option.value" :name="option.name" v-model="filters[items]['data'][option.name]">
                                                <span class="check-recherche"></span>
                                                <div class="txt-check text-gray">
                                                    {{option.display}}
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                </ul>                
                            </li>
                        </ul>
                    </li>
                </template>
            </template>
        </ul>
    </div>
        
</template>

<script>
import { isUserFrench } from '@/utils';
export default {
  name: "filterSection",
  data() { 
        return {            
            filters : 
                {   
                    communs : {
                        "title" : this.$t("Common"),
                        "items" :  [
                            {
                                "sub_title" : this.$t("Treatment line (Oncology)"),
                                "options" : [
                                    { name:"communs_treatment_line", value:"First line", display:this.$t("First line")}, 
                                    { name:"communs_treatment_line", value:"Second line", display:this.$t("Second line")}, 
                                    { name:"communs_treatment_line", value:"Third line", display:this.$t("Third line")},
                                    { name:"communs_treatment_line",value:"Rescue line", display:this.$t("Rescue line")},
                                    { name:"communs_treatment_line",value:"Maintenance", display:this.$t("Maintenance")},
                                    { name:"communs_treatment_line",value:"adjuvant", display:this.$t("adjuvant")},
                                    { name:"communs_treatment_line",value:"neo-adjuvant", display:this.$t("neo-adjuvant")}, 
                                    { name:"communs_treatment_line",value:"n/a", display:this.$t("n/a")}],
                                "style" : "two-column",
                            },
                            {
                                "sub_title" : this.$t("Specificity"),
                                "options" : [{name:"communs_treatment_specificity" , value:'text'}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : this.$t("Administration"),
                                "options" : [
                                    { name:"communs_treatment_administration", value :"combination", display:"Combination therapy"},
                                    { name:"communs_treatment_administration", value :"monotherapy", display:"Monotherapy"}],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : this.$t("Type of MA"),
                                "options" : [
                                    { name:"communs_type_ma", value1:"include_Biosimilaire", value2: "exclude_Biosimilaire", display:this.$t("check_biosimilar")},
                                    { name:"communs_type_ma",value1:"include_Générique",value2:"exclude_Générique", display:this.$t("check_generic")},
                                    { name:"communs_type_ma",value1:"include_Hybride",value2:"exclude_Hybride", display : this.$t("check_hybrid")}, 
                                    { name:"communs_type_ma",value1:"include_Similaire",value2:"exclude_Similaire", display:this.$t("check_similar")}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : this.$t("Classification"),
                                "options" : [
                                    { name:"communs_particularity", value1:"include_First in class", value2:"exclude_First in class", display:this.$t("filter.check_first_in_class")},
                                    { name:"communs_particularity", value1:"include_Nouvelle entité", value2:"exclude_Nouvelle entité", display:this.$t("filter.check_new_molecular_entity")},
                                    { name:"communs_particularity",value1:"include_Nouveau produit",value2:"exclude_Nouveau produit", display:this.$t("filter.check_new_product")},
                                    { name:"communs_particularity",value1:"include_Nouvelle indication",value2:"exclude_Nouvelle indication", display:this.$t("filter.check_new_indication")},
                                    { name:"communs_particularity", value1:"include_Nouvelle association", value2:"exclude_Nouvelle association", display:this.$t("filter.check_new_combination")},
                                    { name:"communs_particularity",value1:"include_Complément de gamme",value2:"exclude_Complément de gamme", display:this.$t("filter.check_additional_range")},
                                    { name:"communs_particularity",value1:"include_Change to an existing indication",value2:"exclude_Change to an existing indication", display:this.$t("filter.check_change_existing_indication")}],
                                "style" : "p-0 w-100"
                            }
                        ],
                        "data" : {
                            "communs_treatment_line":[], 
                            "communs_treatment_specificity" : '' , 
                            "communs_treatment_administration" : [], 
                            "communs_type_ma": [], 
                            "communs_particularity" : []
                            },
                        "show" : false
                    },
                    Ema : {
                        "title" : "EMA",
                        "items" : [
                            {
                                "sub_title" : "Type of MA",
                                "options" : [
                                    {name : "check_atmps", value:1, display:this.$t("check_atmps")},
                                    {name : "check_biosimilar", value:1, display:this.$t("check_biosimilar")},
                                    {name : "check_conditional_ma", value:1, display:this.$t("check_conditional_ma")},
                                    {name : "check_ma_under_except", value:1, display:this.$t("check_ma_under_except")},
                                    {name : "check_generic", value:1, display:this.$t("check_generic")},
                                    {name : "check_switched_non_conditional", value:1, display:this.$t("check_switched_non_conditional")},
                                    {name : "check_hybrid", value:1, display:this.$t("check_hybrid")},
                                    {name : "check_similar", value:1, display:this.$t("check_similar")} ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : this.$t("filter.status"),
                                "options" : [
                                    {name : "ema_status", value:1, display:this.$t("decision.Authorized")},
                                    {name : "ema_status", value:2, display:this.$t("decision.Refused")},
                                    {name : "ema_status", value:3, display:this.$t("Suspended")},
                                    {name : "ema_status", value:4, display:this.$t("decision.Withdrawn post-authorisation")},
                                    {name : "ema_status", value:5, display:this.$t("decision.Withdrawn initial authorization")}],
                                "style" : "p-0 w-100"
                            }
                        ],
                        "data" : {
                            "check_atmps" : [],
                            "check_biosimilar" : [], 
                            "check_conditional_ma" : [],
                            "check_ma_under_except" : [],
                            "check_generic" : [],
                            "check_switched_non_conditional" : [],
                            "check_hybrid" : [],
                            "check_similar" : [],
                            "ema_status" : []},
                        "show" : false
                    },
                    Fda : {
                        "title" : "FDA",
                        "items" : [
                            {
                                "sub_title" : "Type of marketing authorisation(MA)",
                                "options" : [
                                    {name : "check_fda_biosimilar", value:1, display:this.$t("check_biosimilar")},
                                    {name : "check_fda_generic", value:1, display:this.$t("check_generic")},
                                    {name : "check_fda_hybrid", value:1, display:this.$t("check_hybrid")},
                                    {name : "check_fda_similar", value:1, display:this.$t("check_similar")}],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "Review classification",
                                "options" : [
                                    {name : "check_priority_review_drug", value:1, display:this.$t("check_priority_review_drug")},
                                    {name : "check_standard_drug_review", value:1, display:this.$t("check_standard_drug_review")},
                                    {name : "check_accelerated_approval", value:1, display:this.$t("check_accelerated_approval")},
                                    {name : "check_breakthrough_therapy", value:1, display:this.$t("check_breakthrough_therapy")},
                                    {name : "check_fast_track", value:1, display:this.$t("check_fast_track")},
                                    {name : "check_rare_pediatric_disease", value:1, display:this.$t("check_rare_pediatric_disease")},
                                    {name : "check_rolling_review", value:1, display:this.$t("check_rolling_review")},
                                    {name : "check_regenerative_med_adv_therapy_designation", value:1, display:this.$t("check_regenerative_med_adv_therapy_designation")},
                                    {name : "emergency_use_authorization", value:1, display:this.$t("emergency_use_authorization")}],
                                "style" : "p-0 w-100"
                            }
                        ],
                        "data" : {
                            "check_fda_biosimilar" : [],  
                            "check_fda_generic" : [],  
                            "check_fda_hybrid" : [],  
                            "check_fda_similar" : [],  
                            "check_priority_review_drug" : [],  
                            "check_standard_drug_review" : [],  
                            "check_accelerated_approval" : [],  
                            "check_breakthrough_therapy" : [],  
                            "check_fast_track" : [],  
                            "check_rare_pediatric_disease" : [],  
                            "check_rolling_review" : [],  
                            "check_regenerative_med_adv_therapy_designation" : [],  
                            "emergency_use_authorization" : [],  
                            },
                        "show" : false
                    },
                    Transparence : {
                        "title" : this.$t('TC'),
                        "items" : [
                            {
                                "sub_title" : this.$t("filter.transparency_reason_for_request"),
                                "options" : [
                                    {name : "trs_rq_ex", value:"INS", display:this.$t("filter.transparency_reason_for_request_ins")},
                                    {name : "trs_rq_ex", value:"REINS", display:this.$t("filter.transparency_reason_for_request_reins")},
                                    {name : "trs_rq_ex", value:"EIT", display:this.$t("filter.transparency_reason_for_request_eit")},
                                    {name : "raison_reevaluation", value:"Request of authorities", display:this.$t("filter.Re-assessment on request of authorities")},
                                    {name : "raison_reevaluation", value:"Request of sponsor", display:this.$t("filter.Re-assessment on request of sponsor")},
                                    {name : "trs_rq_ex", value:"REEVAL", display:this.$t("filter.transparency_reason_for_request_reeval")},
                                    {name : "trs_rq_ex", value:"RADIATION", display:this.$t("filter.transparency_reason_for_request_radiation")},
                                    {name : "trs_rq_ex", value:"Modification de l’indication", display:this.$t("filter.Modification de l’indication")},
                                    {name : "trs_rq_ex", value:"Remboursement non demandé", display:this.$t("filter.Remboursement non demandé")}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : this.$t("filter.TRS_motif"),
                                "options" : [
                                    {name : "trs_motif", value:"SS", display:this.$t("filter.SS")},
                                    {name : "trs_motif", value:"SSCOLL", display:this.$t("filter.SSCOLL")},
                                    {name : "trs_motif", value:"COLL", display:this.$t("filter.COLL")}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : this.$t("Prise en charge"),
                                "options" : [
                                    {name : "trs_indication_jo_particularites", value:"Prise en charge", display:this.$t("Prise en charge")},
                                    {name : "trs_indication_jo_particularites", value:"Non pris en charge", display:this.$t("Non pris en charge")},
                                    {name : "trs_indication_jo_particularites", value:"En cours", display:this.$t("En cours")},
                                    {name : "trs_indication_jo_particularites", value:"Modification", display:this.$t("Modification de l'indication remboursable")},
                                    {name : "trs_indication_jo_particularites", value:"Radiation", display:this.$t("Radiation")}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : this.$t("transparence.phase_contradictoire.SMR"),
                                "options" : [
                                    {name : "trs_smr_lv", value:"important", display:this.$t("filter.transparency_smr_level_substantial")},
                                    {name : "trs_smr_lv", value:"modéré", display:this.$t("filter.transparency_smr_level_moderate")},
                                    {name : "trs_smr_lv", value:"faible", display:this.$t("filter.transparency_smr_level_minor")},
                                    {name : "trs_smr_lv", value:"insuffisant", display:this.$t("filter.transparency_smr_level_insufficient")},
                                    {name : "trs_smr_lv", value:"non évaluable", display:this.$t("filter.transparency_smr_level_na")}],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : this.$t("transparence.ASMR obtained"),
                                "options" : [
                                    {name : "trs_asmr_lv_ob", value:"I", display:"I"},
                                    {name : "trs_asmr_lv_ob", value:"II", display:"II"},
                                    {name : "trs_asmr_lv_ob", value:"III", display:"III"},
                                    {name : "trs_asmr_lv_ob", value:"IV", display:"IV"},
                                    {name : "trs_asmr_lv_ob", value:"V", display:"V"}],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : this.$t("TRS_AMM"),
                                "options" : [
                                    {name : "TRS_type_AMM", value:"AMM conditionnelle", display:this.$t("check_conditional_ma")},
                                    {name : "TRS_type_AMM", value:"AMM sous circonstances exceptionnelles", display:this.$t("check_ma_under_except")},
                                    {name : "TRS_type_AMM", value:"Biosimilaire", display:this.$t("check_biosimilar")},
                                    {name : "TRS_type_AMM", value:"Hybride", display:this.$t("check_hybrid")},
                                    {name : "TRS_type_AMM", value:"Generic", display:this.$t("check_generic")}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "ATU",
                                "options" : [
                                    {name : "trs_atu_octroi", value:1, display:this.$t("filter.trs_atu_octroi")},
                                    {name : "trs_rq_ex", value:"INS POST-ATU", display:this.$t("filter.transparency_reason_for_request_ins_post_atu")},
                                    {name : "trs_show_generic", value:1, display:this.$t("filter.show_generic")},
                                    {name : "trs_hide_addition_range", value:1, display:this.$t("Hide addition range")}],
                                "style" : "p-0 w-100"
                            },
                        ],
                        "data" : {
                            "trs_rq_ex" : [],
                            "raison_reevaluation" : [],
                            "trs_motif" : [],
                            "trs_indication_jo_particularites" : [],
                            "trs_smr_lv" : [],
                            "trs_asmr_lv_ob" : [],
                            "TRS_type_AMM" : [],
                            "trs_atu_octroi" : [],
                            "trs_show_generic" : [],
                            "trs_hide_addition_range" : []
                            },
                        "show" : false   
                    },
                    Ceesp : {
                        "title" : "CEESP",
                        "items" : [
                            {
                                "sub_title" : this.$t("filter.objet_1"),
                                "options" : [
                                    {name : "ceesp_conclusion", value:1, display:this.$t("filter.conclusion_1")},
                                    {name : "ceesp_conclusion", value:2, display:this.$t("filter.conclusion_2")}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : this.$t("filter.appreciation"),
                                "options" : [
                                    {name : "ceesp_appreciation", value:"Conforme sans réserve", display:this.$t("filter.Conforme sans réserve")},
                                    {name : "ceesp_appreciation", value:"Acceptable avec réserves mineures", display:this.$t("filter.Acceptable avec réserves mineures")},
                                    {name : "ceesp_appreciation", value:"Acceptable avec réserves importantes", display:this.$t("filter.Acceptable avec réserves importantes")},
                                    {name : "ceesp_appreciation", value:"Non conforme (réserve majeure)", display:this.$t("filter.Non conforme (réserve majeure)")},
                                    {name : "ceesp_appreciation", value:"Non documenté", display:this.$t("Non documenté")}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : this.$t("filter.additional_data"),
                                "options" : [
                                    {name : "ceesp_additional_data", value:"oui", display:this.$t("Oui")},
                                    {name : "ceesp_additional_data", value:"Non", display:this.$t("Non")}],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : this.$t("filter.Budgetary impact"),
                                "options" : [
                                    {name : "ceesp_budget_impact", value:"oui", display:this.$t("Oui")},
                                    {name : "ceesp_budget_impact", value:"Non", display:this.$t("Non")}],
                                "style" : "two-column"
                            }
                        ],
                        "data" : {
                            "ceesp_conclusion" : [],
                            "ceesp_appreciation" : [],
                            "ceesp_additional_data" : [],
                            "ceesp_budget_impact" : []
                            },
                        "show" : false
                    },
                    Nice : {
                        "title" : "NICE",
                        "items" : [
                            {
                                "sub_title" : "Appraisal process",
                                "options" : [
                                    {name : "nice_process", value:"STA", display:"Single technology appraisal"},
                                    {name : "nice_process", value:"MTA", display:"Multiple technology appraisal"},
                                    {name : "nice_process", value:"HST", display:"Highly specialised technologies"},
                                    {name : "cdf", value:1, display:"CDF reconsideration"},
                                    {name : "nice_process", value:"Fast track appraisal (FTA)", display:"Fast track appraisal (FTA)"},
                                    {name : "nice_process", value:"STA review", display:"STA review"},
                                    {name : "nice_process", value:"MTA review", display:"MTA review"}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "NICE decision",
                                "options" : [
                                    {name : "nice_approved", value:"Recommended", display:"Recommended"},
                                    {name : "nice_approved", value:"Recommended for use within the CDF", display:"Recommended for use within the CDF"},
                                    {name : "nice_approved", value:"Not recommended", display:"Not recommended"},
                                    {name : "nice_approved", value:"Unable to recommend", display:"Unable to recommend"}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Commercial arrangements",
                                "options" : [
                                    {name : "nice_patient_access_scheme_pas", value:1, display:"PAS"},
                                    {name : "nice_simple_discount_scheme", value:1, display:"Simple discount scheme"},
                                    {name : "nice_complex_scheme", value:1, display:"Complex scheme"},
                                    {name : "nice_commercial_access_agreement", value:1, display:"CAA"}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Early access",
                                "options" : [
                                    {name : "nice_eams_date", value:1, display:"Yes"},
                                    {name : "nice_eams_date", value:2, display:"No"}],
                                "style" : "two-column"
                            },
                        ],
                        "data" : {
                            "nice_process" : [], 
                            "cdf" : [], 
                            "nice_approved" : [], 
                            "nice_patient_access_scheme_pas" : [], 
                            "nice_simple_discount_scheme" : [], 
                            "nice_complex_scheme" : [], 
                            "nice_commercial_access_agreement" : [], 
                            "nice_eams_date" : []
                            },
                        "show" : false
                    },
                    Cdf : {
                        "title" : "CDF",
                        "items" : [
                            {
                                "sub_title" : "Type of submission",
                                "options" : [
                                    {name : "cdf_submission", value:"Original submission", display:"Original submission"},
                                    {name : "cdf_submission", value:"Updated submission", display:"Updated submission"}],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "Retention within CDF",
                                "options" : [
                                    {name : "cdf_retention_within_the_cdf", value:1, display:this.$t("Oui")},
                                    {name : "cdf_retention_within_the_cdf", value:2, display:this.$t("Non")}],
                                "style" : "two-column"
                            },
                        ],
                        "data" : {"cdf_submission" : [], "cdf_retention_within_the_cdf" : []},
                        "show" : false
                    },
                    Gba : {
                        "title" : "G-BA",
                        "items" : [
                            {
                                "sub_title" : "Added benefit",
                                "options" : [
                                    {name : "gba_added_benefit", value:2, display:this.$t("gba_added_benefit_id_2")},
                                    {name : "gba_added_benefit", value:3, display:this.$t("gba_added_benefit_id_3")},
                                    {name : "gba_added_benefit", value:4, display:this.$t("gba_added_benefit_id_4")},
                                    {name : "gba_added_benefit", value:5, display:this.$t("gba_added_benefit_id_5")},
                                    {name : "gba_added_benefit", value:6, display:this.$t("gba_added_benefit_id_6")},
                                    {name : "gba_added_benefit", value:7, display:this.$t("gba_added_benefit_id_7")}],
                                "style" : "two-column"
                            }
                        ],
                        "data" : {"gba_added_benefit" : []},
                        "show" : false
                    },
                    Iqwig : {
                        "title" : "IQWiG",
                        "items" : [
                            {
                                "sub_title" : "Probability | of added benefit",
                                "options" : [
                                    {name : "iqwig_probability", value:2, display:this.$t("iqwig_probability_id_2")},
                                    {name : "iqwig_probability", value:3, display:this.$t("iqwig_probability_id_3")},
                                    {name : "iqwig_probability", value:4, display:this.$t("iqwig_probability_id_4")},
                                    {name : "iqwig_probability", value:5, display:this.$t("iqwig_probability_id_5")},
                                    {name : "iqwig_probability", value:6, display:this.$t("iqwig_probability_id_6")}],
                                "style" : "p-0 w-100",
                            },
                            {
                                "sub_title" : "Conclusion | of added benefit",
                                "options" : [
                                    {name : "iqwig_added_benefit_overall", value:2, display:this.$t("iqwig_added_benefit_overall_id_2")},
                                    {name : "iqwig_added_benefit_overall", value:3, display:this.$t("iqwig_added_benefit_overall_id_3")},
                                    {name : "iqwig_added_benefit_overall", value:4, display:this.$t("iqwig_added_benefit_overall_id_4")},
                                    {name : "iqwig_added_benefit_overall", value:5, display:this.$t("iqwig_added_benefit_overall_id_5")},
                                    {name : "iqwig_added_benefit_overall", value:6, display:this.$t("iqwig_added_benefit_overall_id_6")},
                                    {name : "iqwig_added_benefit_overall", value:7, display:this.$t("iqwig_added_benefit_overall_id_7")}],
                                "style" : "two-column",
                            },
                            {
                                "sub_title" : "Type of commission",
                                "options" : [
                                    {name : "iqwig_commission", value:2, display:this.$t("iqwig_commission_id_2")},
                                    {name : "iqwig_commission", value:3, display:this.$t("iqwig_commission_id_3")},
                                    {name : "iqwig_commission", value:4, display:this.$t("iqwig_commission_id_4")}],
                                "style" : "two-column",
                            }
                        ],
                        "data" : {"iqwig_probability" : [], "iqwig_added_benefit_overall" : [], "iqwig_commission" : []},
                        "show" : false 
                    },
                    Spain : {
                        "title" : "SPAIN", 
                        "items" : [
                            {
                                "sub_title" : "Agency",
                                "options" : [
                                    {name : "spain_agency", value:"(AEMPS) IPT", display:"(AEMPS) IPT"},
                                    {name : "spain_agency", value:"CADIME", display:"CADIME"},
                                    {name : "spain_agency", value:"CAMHDA", display:"CAMHDA"},
                                    {name : "spain_agency", value:"Cantabria", display:"Cantabria"},
                                    {name : "spain_agency", value:"CEVIME", display:"CEVIME"},
                                    {name : "spain_agency", value:"CIEMPS", display:"CIEMPS"},
                                    {name : "spain_agency", value:"COMAPC", display:"COMAPC"},
                                    {name : "spain_agency", value:"CURMP", display:"CURMP"},
                                    {name : "spain_agency", value:"DGF", display:"DGF"},
                                    {name : "spain_agency", value:"GENESIS", display:"GENESIS"},
                                    {name : "spain_agency", value:"IB-SALUT", display:"IB-SALUT"},
                                    {name : "spain_agency", value:"Oficina de evaluacion de medicamentos - Junta de extremadura", display:"Oficina de evaluacion de medicamentos - Junta de extremadura"},
                                    {name : "spain_agency", value:"RIOJA SALUD", display:"RIOJA SALUD"},
                                    {name : "spain_agency", value:"SACYL", display:"SACYL"},
                                    {name : "spain_agency", value:"Salud Madrid", display:"Salud Madrid"},
                                    {name : "spain_agency", value:"SERGAS", display:"SERGAS"},
                                    {name : "spain_agency", value:"Servicio aragones de salud", display:"Servicio aragones de salud"},
                                    {name : "spain_agency", value:"SNS", display:"SNS"}],
                                "style" : "p-0 w-100",
                            },
                            {
                                "sub_title" : "Territorial scale",
                                "options" : [
                                    {name : "spain_region", value:"(AEMPS) IPT", display:"(AEMPS) IPT"},
                                    {name : "spain_region", value:"Andalucia", display:"Andalucia"},
                                    {name : "spain_region", value:"Aragon", display:"Aragon"},
                                    {name : "spain_region", value:"Asturias", display:"Asturias"},
                                    {name : "spain_region", value:"Cantabria", display:"Cantabria"},
                                    {name : "spain_region", value:"Castilla la mancha", display:"Castilla la mancha"},
                                    {name : "spain_region", value:"Castilla y Leon", display:"Castilla y Leon"},
                                    {name : "spain_region", value:"Catalonia", display:"Catalonia"},
                                    {name : "spain_region", value:"Comunidad Valenciana", display:"Comunidad Valenciana"},
                                    {name : "spain_region", value:"Extremadura", display:"Extremadura"},
                                    {name : "spain_region", value:"Galicia", display:"Galicia"},
                                    {name : "spain_region", value:"Islas Baleares", display:"Islas Baleares"},
                                    {name : "spain_region", value:"La rioja", display:"La rioja"},
                                    {name : "spain_region", value:"Madrid", display:"Madrid"},
                                    {name : "spain_region", value:"Murcia", display:"Murcia"},
                                    {name : "spain_region", value:"National", display:"National"},
                                    {name : "spain_region", value:"Navarra", display:"Navarra"},
                                    {name : "spain_region", value:"Pais Vasco", display:"Pais Vasco"}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Recommendation",
                                "options" : [
                                    {name : "spain_recommendation", value:"Recommended", display:"Recommended"},
                                    {name : "spain_recommendation", value:"Recommended with limitation", display:"Recommended with limitation"},
                                    {name : "spain_recommendation", value:"Not recommended", display:"Not recommended"}],
                                "style" : "p-0 w-100",
                            },
                            {
                                "sub_title" : "Reimbursement decision",
                                "options" : [
                                    {name : "spain_reimbursement_decision", value:1, display:"National health insurance"},
                                    {name : "spain_reimbursement_decision", value:2, display:"Hospital list"}],
                                "style" : "p-0 w-100",
                            },    
                        ],
                        "data" : {"spain_agency" : [], "spain_region" : [], "spain_recommendation" : [], "spain_reimbursement_decision" : [] },
                        "show" : false
                    },
                    Italy : {
                        "title" : "ITALY", 
                        "items" : [
                            {
                                "sub_title" : "Region", 
                                "options" : [
                                    {name : "italy_region", value:"AIFA", display:"AIFA (National)"},
                                    {name : "italy_region", value:"Emilia-Romagna", display:"Emilia-Romagna"},
                                    {name : "italy_region", value:"Veneto", display:"Veneto"}],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "Drug class", 
                                "options" : [
                                    {name : "italy_drug_class", value:"A: Essential drug", display:"A: Essential drug"},
                                    {name : "italy_drug_class", value:"H: Hospital drug", display:"H: Hospital drug"},
                                    {name : "italy_drug_class", value:"C: Not essential", display:"C: Not essential"},
                                    {name : "italy_drug_class", value:"Cnn: Centrally approved", display:"Cnn: Centrally approved"},
                                    {name : "italy_drug_class", value:"648law: Compassionate use", display:"648law: Compassionate use"}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Reimbursement", 
                                "options" : [
                                    {name : "italy_reimbursement", value:"Yes", display:"Yes"},
                                    {name : "italy_reimbursement", value:"No", display:"No"},
                                ],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "Management entry agreement", 
                                "options" : [
                                    {name : "italy_mea", value:"No MEA", display:"No MEA"},
                                    {name : "italy_mea", value:"Cost sharing", display:"Cost sharing"},
                                    {name : "italy_mea", value:"Risk sharing", display:"Risk sharing"},
                                    {name : "italy_mea", value:"Payment by result", display:"Payment by result"},
                                    {name : "italy_mea", value:"Success fee", display:"Success fee"},
                                    {name : "italy_mea", value:"Capping/payback", display:"Capping/payback"},
                                    {name : "italy_mea", value:"Fixed cost per patient", display:"Fixed cost per patient"},
                                    {name : "italy_mea", value:"Price/Volume", display:"Price/Volume"}],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "Recommendation", 
                                "options" : [
                                    {name : "italy_limitation", value:"Recommended", display:this.$t("conclusion.Recommended")},
                                    {name : "italy_limitation", value:"Recommended with limitation", display:this.$t("conclusion.Recommended with limitations")},
                                    {name : "italy_limitation", value:"Unable to recommend", display:this.$t("conclusion.Unable to recommend")},
                                    {name : "italy_limitation", value:"Not recommended", display:this.$t("conclusion.Not recommended")}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Early Access", 
                                "options" : [
                                    {name : "italy_law_648", value:1, display:"Law 648/96"}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Therapeutic innovation", 
                                "options" : [
                                    {name : "italy_therapeutic_innovation", value:"Yes", display:"Yes"},
                                    {name : "italy_therapeutic_innovation", value:"No", display:"No"}],
                                "style" : "two-column"
                            },        
                        ],
                        "data" : {
                            "italy_region" : [],
                            "italy_drug_class" : [], 
                            "italy_reimbursement" : [], 
                            "italy_mea" : [], 
                            "italy_limitation" : [], 
                            "italy_law_648" : [],
                            "italy_therapeutic_innovation" : []
                            },
                        "show" : false
                    },
                    Tlv : {
                        "title" : "TLV",
                        "items" : [
                            {
                                "sub_title" : "Decision", 
                                "options" : [
                                    {name : "tlv_dec", value:"Approved", display:"Approved"},
                                    {name : "tlv_dec", value:"Approved with restriction", display:"Approved with restriction"},
                                    {name : "tlv_dec", value:"Approved with conditions", display:"Approved with conditions"},
                                    {name : "tlv_dec", value:"Approved with restriction and condition", display:"Approved with restriction and condition"},
                                    {name : "tlv_dec", value:"Partly rejected", display:"Partly rejected"},
                                    {name : "tlv_dec", value:"Rejected", display:"Rejected"},
                                    {name : "tlv_dec", value:"Decision by the County Councils", display:"Decision by the County Councils"}],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Type of decision", 
                                "options" : [
                                    {name : "tlv_decision_type", value:"New drug", display:"New drug"},
                                    {name : "tlv_decision_type", value:"New indication", display:"New indication"},
                                    {name : "tlv_decision_type", value:"Changed price", display:"Changed price"},
                                    {name : "tlv_decision_type", value:"Re-evaluation", display:"Re-evaluation"},
                                    {name : "tlv_decision_type", value:"Follow-up decision", display:"Follow-up decision"},
                                    {name : "tlv_decision_type", value:"New application", display:"New application"},
                                    {name : "tlv_decision_type", value:"New formulation", display:"New formulation"}],
                                "style" : "two-column"
                            }
                        ],
                        "data" : {"tlv_dec" : [], "tlv_decision_type" : []},
                        "show" : false
                    },
                    Ncpe : {
                        "title" : "NCPE", 
                        "items" : [
                            {
                                "sub_title" : "Rapid review outcome", 
                                "options" : [
                                    {name : "rapid_review_outcome", value:"Full pharmacoeconomic evaluation recommended", display:"Full pharmacoeconomic evaluation recommended"},
                                    {name : "rapid_review_outcome", value:"Full pharmacoeconomic evaluation not recommended", display:"Full pharmacoeconomic evaluation not recommended"}
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Pharmacoeconomic evaluation outcome", 
                                "options" : [
                                    {name : "pharmacoeconomic_evaluation_outcome", value:"Reimbursement recommended", display:"Reimbursement recommended"},
                                    {name : "pharmacoeconomic_evaluation_outcome", value:"Reimbursement not recommended", display:"Reimbursement not recommended"},
                                    {name : "pharmacoeconomic_evaluation_outcome", value:"Reimbursement not recommended at submitted price", display:"Reimbursement not recommended at submitted price"}
                                ],
                                "style" : "p-0 w-100"
                            },
                        ],
                        "data" : {"rapid_review_outcome" : [], "pharmacoeconomic_evaluation_outcome" : []},
                        "show" : false
                    },
                    Smc : {
                        "title" : "SMC", 
                        "items" : [
                            {
                                "sub_title" : "Submission Type", 
                                "options" : [
                                    {name : "smc_submission", value:"Full submission", display:this.$t("filter.Full submission")},
                                    {name : "smc_submission", value:"Abbreviated Submission", display:this.$t("filter.Abbreviated Submission")},
                                    {name : "smc_submission", value:"Resubmission", display:this.$t("filter.Resubmission")},
                                    {name : "smc_submission", value:"Non submission", display:this.$t("filter.Non submission")},
                                    {name : "smc_submission", value:"IRP", display:this.$t("filter.IRP")},
                                    {name : "smc_submission", value:"Ultra-orphan initial assessment", display:"Ultra-orphan initial assessment"}
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "SMC decision", 
                                "options" : [
                                    {name : "smc_status", value:"Accepted", display:this.$t("filter.smc_Accepted")},
                                    {name : "smc_status", value:"Restricted", display:this.$t("filter.smc_Restricted")},
                                    {name : "smc_status", value:"Not Recommended", display:this.$t("filter.smc_Not Recommended")},
                                    {name : "smc_status", value:"Withdrawn", display:this.$t("filter.smc_Withdrawn")},
                                    {name : "smc_status", value:"Superseded", display:this.$t("filter.smc_Superseded")},
                                    {name : "smc_status", value:"Interim acceptance", display:this.$t("filter.smc_Interim acceptance")}
                                ],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "Approved PAS", 
                                "options" : [
                                    {name : "smc_approved_patient_access_scheme", value:1, display:"Approved PAS"}
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Process", 
                                "options" : [
                                    {name : "smc_process", value:1, display:"End-of-life"},
                                    {name : "smc_process", value:2, display:"Orphan"},
                                    {name : "smc_process", value:3, display:"Ultra-orphan"}
                                ],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "Early access", 
                                "options" : [
                                    {name : "smc_eams_date", value:1, display:"Yes"},
                                    {name : "smc_eams_date", value:2, display:"No"}
                                ],
                                "style" : "two-column"
                            },
                        ],
                        "data" : {
                            "smc_submission" : [], 
                            "smc_status" : [], 
                            "smc_approved_patient_access_scheme" : [], 
                            "smc_process" : [], 
                            "smc_eams_date" : [] 
                            },
                        "show" : false
                    },
                    Inami : {
                        "title" : "INAMI", 
                        "items" : [
                            {
                                "sub_title" : "Reimbursement condition", 
                                "options" : [
                                    {name : "inami_reimbursement_condition", value:1, display:"Chapter I"},
                                    {name : "inami_reimbursement_condition", value:2, display:"Chapter II"},
                                    {name : "inami_reimbursement_condition", value:3, display:"Chapter III"},
                                    {name : "inami_reimbursement_condition", value:4, display:"Chapter IV"},
                                    {name : "inami_reimbursement_condition", value:5, display:"Chapter IV bis"},
                                    {name : "inami_reimbursement_condition", value:6, display:"Chapter V"}
                                ],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "Reimbursement category", 
                                "options" : [
                                    {name : "inami_reimbursement_category", value:1, display:"A"},
                                    {name : "inami_reimbursement_category", value:2, display:"B"},
                                    {name : "inami_reimbursement_category", value:3, display:"C"},
                                    {name : "inami_reimbursement_category", value:4, display:"Cx"},
                                    {name : "inami_reimbursement_category", value:5, display:"Cs"}
                                ],
                                "style" : "two-column"
                            }
                        ],
                        "data" : {"inami_reimbursement_condition" : [], "inami_reimbursement_category" : []},
                        "show" : false
                    },
                    Zinl : {
                        "title" : "ZIN", 
                        "items" : [
                            {
                                "sub_title" : "Type of assessment", 
                                "options" : [
                                    {name : "zinl_type_of_assessment", value:1, display:"GVS (extra mural)"},
                                    {name : "zinl_type_of_assessment", value:2, display:"Expensive Medicines (intra mural) T=0"},
                                    {name : "zinl_type_of_assessment", value:3, display:"Expensive Medicines (intra mural) T=4"},
                                    {name : "zinl_type_of_assessment", value:4, display:"Orphan drugs"},
                                    {name : "zinl_type_of_assessment", value:5, display:"Non-registered indication"}
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Economic evaluation required", 
                                "options" : [
                                    {name : "zinl_economic_evaluation_required", value:"oui", display:this.$t("Oui")},
                                    {name : "zinl_economic_evaluation_required", value:"Non", display:this.$t("Non")},
                                ],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "Reimbursement", 
                                "options" : [
                                    {name : "zinl_reimbursement", value:"Yes", display:"Yes"},
                                    {name : "zinl_reimbursement", value:"No", display:"No"}
                                ],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "Economic conclusion", 
                                "options" : [
                                    {name : "zinl_hta_agency_conclusion_on_economic_evidence", value:1, display:"PE assessment sufficient"},
                                    {name : "zinl_hta_agency_conclusion_on_economic_evidence", value:2, display:"PE assessment insufficient"},
                                    {name : "zinl_hta_agency_conclusion_on_economic_evidence", value:3, display:"Initial PE evaluation and design Outcomes Research sufficient"},
                                    {name : "zinl_hta_agency_conclusion_on_economic_evidence", value:5, display:"Initial PE evaluation and design Outcomes Research insufficient"},
                                    {name : "zinl_hta_agency_conclusion_on_economic_evidence", value:7, display:"Outcomes Research sufficient / PE sufficient"},
                                    {name : "zinl_hta_agency_conclusion_on_economic_evidence", value:8, display:"Outcomes Research sufficient / PE insufficient"},
                                    {name : "zinl_hta_agency_conclusion_on_economic_evidence", value:9, display:"Outcomes Research insufficient / PE sufficient"},
                                    {name : "zinl_hta_agency_conclusion_on_economic_evidence", value:10, display:"Outcomes Research insufficient / PE insufficient"}
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Recommendation", 
                                "options" : [
                                    {name : "zinl_recommendation", value:1, display:this.$t("decision_1")},
                                    {name : "zinl_recommendation", value:2, display:this.$t("decision_2")},
                                    {name : "zinl_recommendation", value:3, display:this.$t("decision_3")},
                                    {name : "zinl_recommendation", value:4, display:this.$t("decision_4")},
                                    {name : "zinl_recommendation", value:5, display:this.$t("decision_5")},
                                    {name : "zinl_recommendation", value:6, display:this.$t("decision_6")},
                                    {name : "zinl_recommendation", value:7, display:this.$t("decision_7")},
                                    {name : "zinl_recommendation", value:8, display:this.$t("decision_8")},
                                    {name : "zinl_recommendation", value:9, display:this.$t("decision_9")}
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Final decision MoH", 
                                "options" : [
                                    {name : "zinl_decision", value:1, display:this.$t("decision_1")},
                                    {name : "zinl_decision", value:2, display:this.$t("decision_2")},
                                    {name : "zinl_decision", value:3, display:this.$t("decision_3")},
                                    {name : "zinl_decision", value:4, display:this.$t("decision_4")},
                                    {name : "zinl_decision", value:5, display:this.$t("decision_5")}
                                ],
                                "style" : "p-0 w-100"
                            }
                        ],
                        "data" : {
                            "zinl_type_of_assessment" : [], 
                            "zinl_economic_evaluation_required" : [], 
                            "zinl_reimbursement" : [], 
                            "zinl_hta_agency_conclusion_on_economic_evidence" : [], 
                            "zinl_recommendation" : [], 
                            "zinl_decision" : [] 
                            },
                        "show" : false
                    },
                    Awmsg : {
                        "title" : "AWMSG", 
                        "items" : [
                            {
                                "sub_title" : "Recommendation", 
                                "options" : [
                                    {name : "awmsg_status", value:"Marketing authorisation withdrawn", display:"Marketing authorisation withdrawn"},
                                    {name : "awmsg_status", value:"Partially superseded", display:"Partially superseded"},
                                    {name : "awmsg_status", value:"Recommended", display:"Recommended"},
                                    {name : "awmsg_status", value:"Recommended with restrictions", display:"Recommended with restrictions"},
                                    {name : "awmsg_status", value:"Superseded", display:"Superseded"}
                                ],
                                "style" : "p-0 w-100",
                                "data" : []
                            },
                            {
                                "sub_title" : "Submission type", 
                                "options" : [
                                    {name : "awmsg_submission_type", value:"Limited Submission", display:"Limited Submission"},
                                    {name : "awmsg_submission_type", value:"Full Submission", display:"Full Submission"},
                                    {name : "awmsg_submission_type", value:"Non-submission", display:"Non-submission"},
                                    {name : "awmsg_submission_type", value:"Independent Review", display:"Independent Review"},
                                    {name : "awmsg_submission_type", value:"Resubmission", display:"Resubmission"},
                                    {name : "awmsg_submission_type", value:"Not applicable", display:"Not applicable"},
                                    {name : "awmsg_submission_type", value:"NICE HST", display:"NICE HST"}
                                ],
                                "style" : "two-column"
                            }
                        ],
                        "data" : {"awmsg_status" : [], "awmsg_submission_type" : []},
                        "show" : false
                    },
                    Usa : {
                        "title" : "ICER", 
                        "items" : [
                            {
                                "sub_title" : "Status", 
                                "options" : [
                                    {name : "usa_status", value:"Published", display:this.$t("Published")},
                                    {name : "usa_status", value:"In development", display:this.$t("In development")}
                                ],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "Topic", 
                                "options" : [
                                    {name : "usa_topic", value:"Non-Small Cell Lung Cancer", display:"Non-Small Cell Lung Cancer"}
                                ],
                                "style" : "p-0 w-100"
                            }
                        ],
                        "data" : {"usa_status" : [], "Topic" : []},
                        "show" : false
                    },  
                    Cadth : {
                        "title" : "CADTH",
                        "items" : [
                            {
                                "sub_title" : "Recommendation",
                                "options" : [
                                    {name : "cadth_recommendation", value:"Reimburse", display:"Reimburse"},
                                    {name : "cadth_recommendation", value:"Reimburse with clinical criteria and/or conditions", display:"Reimburse with clinical criteria and/or conditions"},
                                    {name : "cadth_recommendation", value:"Do not reimburse", display:"Do not reimburse"}
                                ],
                                "style" : "p-0 w-100"
                            }
                        ],
                        "data" : {"cadth_recommendation" : []},
                        "show" : false
                    },
                    Perc : {
                        "title" : "pCODR",
                        "items" : [
                            {
                                "sub_title" : "Recommendation",
                                "options" : [
                                    {name : "perc_recommendation", value:"Reimburse", display:"Reimburse"},
                                    {name : "perc_recommendation", value:"Reimburse with clinical criteria and/or conditions", display:"Reimburse with clinical criteria and/or conditions"},
                                    {name : "perc_recommendation", value:"Do not reimburse", display:"Do not reimburse"},
                                    {name : "perc_recommendation", value:"Unable to recommend reimbursement as a submission was not filed by the manufacturer", display:"Unable to recommend reimbursement as a submission was not filed by the manufacturer"}
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Submission Type",
                                "options" : [
                                    {name : "perc_submission_type", value:"New Indication", display:"New Indication"},
                                    {name : "perc_submission_type", value:"New Drug", display:"New Drug"},
                                    {name : "perc_submission_type", value:"Resubmission", display:"Resubmission"},
                                    {name : "perc_submission_type", value:"Request for Advice", display:"Request for Advice"},
                                    {name : "perc_submission_type", value:"Non-submission", display:"Non-submission"},
                                ],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "", 
                                "options" : [{name : "perc_pre_noc_submission", value:2, display:"Pre NOC Submission"}],
                                "style" : "p-0 w-100"
                            }
                        ],
                        "data" : {"perc_recommendation" : [], "perc_submission_type" : [], "perc_pre_noc_submission" : []},
                        "show" : false
                    },  
                    Colombia : {
                        "title" : "IETS",
                        "items" : [
                            {
                                "sub_title" : "Decision",
                                "options" : [
                                    {name : "colombia_decision", value:"Cover", display:"Cover"},
                                    {name : "colombia_decision", value:"Cover with evidence generation", display:"Cover with evidence generation"},
                                    {name : "colombia_decision", value:"Cover with price negotiation", display:"Cover with price negotiation"},
                                    {name : "colombia_decision", value:"Restrict coverage", display:"Restrict coverage"},
                                    {name : "colombia_decision", value:"No cover", display:"No cover"}
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Reimbursement decision",
                                "options" : [
                                    {name : "colombia_reimbursement_decision", value:"Include in the National Health Care System Plan", display:"Include in the National Health Care System Plan"},
                                    {name : "colombia_reimbursement_decision", value:"Not included in the National Health Care System Plan", display:"Not included in the National Health Care System Plan"}
                                ],
                                "style" : "p-0 w-100"
                            },
                        ],
                        "data" : {"colombia_decision" : [], "colombia_reimbursement_decision" : [] },
                        "show" : false
                    }, 
                    Peru : {
                        "title" : "PERU",
                        "items" : [
                            {
                                "sub_title" : "Recommendation",
                                "options" : [
                                    {name : "peru_limitation", value:"Recommended", display:"Recommended"},
                                    {name : "peru_limitation", value:"Recommended with limitation", display:"Recommended with limitation"},
                                    {name : "peru_limitation", value:"Unable to recommend", display:"Unable to recommend"},
                                    {name : "peru_limitation", value:"Not recommended", display:"Not recommended"}
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Decision",
                                "options" : [
                                    {name : "peru_decision", value:"Include in Social Health Insurance", display:"Include in Social Health Insurance"},
                                    {name : "peru_decision", value:"No include in Social Health Insurance", display:"No include in Social Health Insurance"},
                                    {name : "peru_decision", value:"Exclude from Social Health Insurance", display:"Exclude from Social Health Insurance"}
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Reimbursement decision",
                                "options" : [
                                    {name : "peru_reimbursement_decision", value:"Reimburse", display:"Reimburse" },
                                    {name : "peru_reimbursement_decision", value:"No reimburse", display:"No reimburse"}
                                ],
                                "style" : "two-column"
                            },
                        ],
                        "data" : {"peru_limitation" : [], "peru_decision" : [], "peru_reimbursement_decision" : []},
                        "show" : false
                    },  
                    Bresil : {
                        "title" : "CONITEC",
                        "items" : [
                            {
                                "sub_title" : "Decision",
                                "options" : [
                                    {name : "bresil_decision_br", value:"Include", display:"Include"},
                                    {name : "bresil_decision_br", value:"Extension of use", display:"Extension of use"},
                                    {name : "bresil_decision_br", value:"Usage restriction", display:"Usage restriction"},
                                    {name : "bresil_decision_br", value:"Exclusion", display:"Exclusion"},
                                    {name : "bresil_decision_br", value:"Do not include", display:"Do not include"},
                                    {name : "bresil_decision_br", value:"Maintain in formulary", display:"Maintain in formulary"},
                                ],
                                "style" : "two-column"
                            }
                        ],
                        "data" : {"bresil_decision_br" : []},
                        "show" : false
                    },  
                    Pbac : {
                        "title" : "PBAC",
                        "items" : [
                            {
                                "sub_title" : "Recommendation",
                                "options" : [
                                    {name : "pbac_recommendation", value:"Outcome", display:"Outcome"},
                                    {name : "pbac_recommendation", value:"Recommended", display:"Recommended"},
                                    {name : "pbac_recommendation", value:"Deferred", display:"Deferred"},
                                    {name : "pbac_recommendation", value:"Rejected", display:"Rejected"},
                                    {name : "pbac_recommendation", value:"Withdrawn", display:"Withdrawn"},
                                    {name : "pbac_recommendation", value:"Awaiting TGA outcomes", display:"Awaiting TGA outcomes"}
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Recommended Listing",
                                "options" : [
                                    {name : "pbac_listing", value:"Unrestricted", display:"Unrestricted"},
                                    {name : "pbac_listing", value:"Restricted", display:"Restricted"},
                                    {name : "pbac_listing", value:"Authority Required (STREAMLINED)", display:"Authority Required (STREAMLINED)"},
                                    {name : "pbac_listing", value:"Authority required", display:"Authority required"},
                                    {name : "pbac_listing", value:"National Immunisation Program", display:"National Immunisation Program"}
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Submission type",
                                "options" : [
                                    {name : "pbac_type", value:"Submission", display:"Submission"},
                                    {name : "pbac_type", value:"Re-submission", display:"Re-submission"},
                                    {name : "pbac_type", value:"Minor submission", display:"Minor submission"},
                                    {name : "pbac_type", value:"Addendum", display:"Addendum"}
                                ],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "Section 100",
                                "options" : [
                                    {name : "pbac_section_100_text", value:"Highly Specialised Drugs Program", display:"Highly Specialised Drugs Program"},
                                    {name : "pbac_section_100_text", value:"Efficient Funding of Chemotherapy", display:"Efficient Funding of Chemotherapy"},
                                    {name : "pbac_section_100_text", value:"Botulinum Toxin Program", display:"Botulinum Toxin Program"},
                                    {name : "pbac_section_100_text", value:"Growth Hormone Program", display:"Growth Hormone Program"},
                                    {name : "pbac_section_100_text", value:"IVF Program", display:"IVF Program"},
                                    {name : "pbac_section_100_text", value:"Opiate Dependence Treatment Program", display:"Opiate Dependence Treatment Program"},
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Listing type",
                                "options" : [
                                    {name : "pbac_listing_type", value:"Matter arising", display:"Matter arising"},
                                    {name : "pbac_listing_type", value:"Matters Outstanding", display:"Matters Outstanding"},
                                    {name : "pbac_listing_type", value:"New listing", display:"New listing"},
                                    {name : "pbac_listing_type", value:"New formulation", display:"New formulation"},
                                    {name : "pbac_listing_type", value:"New indication", display:"New indication"},
                                    {name : "pbac_listing_type", value:"New combination", display:"New combination"},
                                    {name : "pbac_listing_type", value:"New medicine", display:"New medicine"},
                                    {name : "pbac_listing_type", value:"Change to listing", display:"Change to listing"},
                                    {name : "pbac_listing_type", value:"Change to recommended listing", display:"Change to recommended listing"},
                                ],
                                "style" : "p-0 w-100"
                            },
                            {
                                "sub_title" : "Early access",
                                "options" : [
                                    {name : "pbac_priority_review", value:1, display:"Priority review"},
                                    {name : "pbac_provisional_approval", value:1, display:"Provisional approval"},
                                    {name : "pbac_tga_parallel_process", value:1, display:"TGA-PBAC parallel process"}
                                ],
                                "style" : "p-0 w-100"
                            }
                        ],
                        "data" : {
                            "pbac_recommendation" : [], 
                            "pbac_listing" : [], 
                            "pbac_type" : [], 
                            "pbac_section_100_text" : [], 
                            "pbac_listing_type" : [], 
                            "pbac_priority_review" : [],
                            "pbac_provisional_approval" : [],
                            "pbac_tga_parallel_process" : [],
                            },
                        "show" : false
                    },
                    Ptac : {
                        "title" : "PTAC",
                        "items" : [
                            {
                                "sub_title" : "Recommendation",
                                "options" : [
                                    {name : "ptac_recommendation", value:"Recommended", display:"Recommended"},
                                    {name : "ptac_recommendation", value:"Deferred", display:"Deferred"},
                                    {name : "ptac_recommendation", value:"Rejected", display:"Rejected"}
                                ],
                                "style" : "two-column"
                            }
                        ],
                        "data" : {"ptac_recommendation" : []},
                        "show" : false
                    },
                    Pmda : {
                        "title" : "PMDA",
                        "items" : [
                            {
                                "sub_title" : "Priority review",
                                "options" : [
                                    {name : "pmda_priority_review", value:"Yes", display:this.$t("Oui")},
                                    {name : "pmda_priority_review", value:"No", display:this.$t("Non")}
                                ],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "SAKIGAKE designation",
                                "options" : [
                                    {name : "pmda_sakigake_designation", value:"Yes", display:this.$t("Oui")},
                                    {name : "pmda_sakigake_designation", value:"No", display:this.$t("Non")}
                                ],
                                "style" : "two-column"
                            },
                            {
                                "sub_title" : "Genetical recombination",
                                "options" : [
                                    {name : "pmda_genetical_recombination", value:"Yes", display:this.$t("Oui")}
                                ],
                                "style" : "two-column"
                            }
                        ],
                        "data" : {"pmda_priority_review" : [], "pmda_sakigake_designation" : [], "pmda_genetical_recombination" : []},
                        "show" : false
                    },
                    Hira : {
                        "title" : "HIRA",
                        "items" : [
                            {
                                "sub_title" : "Recommendation",
                                "options" : [
                                    {name : "hira_limitation", value:"Recommended", display:this.$t("conclusion.Recommended")},
                                    {name : "hira_limitation", value:"Recommended with limitations", display:this.$t("conclusion.Recommended with limitations")},
                                    {name : "hira_limitation", value:"Unable to recommend", display:this.$t("conclusion.Unable to recommend")},
                                    {name : "hira_limitation", value:"Not recommended", display:this.$t("conclusion.Not recommended")}
                                ],
                                "style" : "p-0 w-100"
                            }
                        ],
                        "data" : {"hira_limitation" : []},
                        "show" : false
                    },
                    Singapore : {
                        "title" : "ACE",
                        "items" : [
                            {
                                "sub_title" : "Reimbursement decision",
                                "options" : [
                                    {name : "singapore_reimbursement_decision", value:"Recommended  for reimbursement by the Medication Assistance Fund", display:"Recommended  for reimbursement by the Medication Assistance Fund"},
                                    {name : "singapore_reimbursement_decision", value:"Not recommended for reimbursement by the Medication Assistance Fund", display:"Not recommended for reimbursement by the Medication Assistance Fund"},
                                    {name : "singapore_reimbursement_decision", value:"Recommended for listing on the Standard Drug List", display:"Recommended for listing on the Standard Drug List"},
                                    {name : "singapore_reimbursement_decision", value:"Not recommended for listing on the Standard Drug List", display:"Not recommended for listing on the Standard Drug List"}
                                ],
                                "style" : "p-0 w-100"
                            }
                        ],
                        "data" : {"singapore_reimbursement_decision" : []},
                        "show" : false
                    },
            },
        }
    },
    mounted () {
        if(this.param_filters !== ''){
            this.param_filters.split('|').forEach(val => {
                if(val !== ''){ 
                    let key = val.split('@')
                    let sub_key = key && key[1] ? key[1].split('=') : ''
                    let value = sub_key && sub_key[1] ? sub_key[1].split('=') : ''
                    if(value[0] && value[0].indexOf('#') != -1){
                        value = value[0].split('#')
                    }
                    if(value && (!value.includes(this.filters[key[0]].data[sub_key[0]]))){
                        this.filters[key[0]].data[sub_key[0]] = value
                    }
                }
            });
        }
    },
    methods:{
        toggle_class(param){
            for (var i in this.filters){
                if(!this.filters[param].show && param == i){
                    this.filters[i].show = true
                }else{
                    this.filters[i].show = false
                }
            }
        },
        active_filter(title){
            if(this.agency_item.includes(title)){
                return true
            }
            return false
        },
        item_data_active(filters){
            if(filters && filters.data){
                for(let key in filters.data) {
                    if(filters.data[key].length > 0){
                        return true
                    }
                }
            }
            return false
        },
        cancel(){
            this.$emit("active",false);
        },
        apply(){
            let apply_filters = ''
            let key_filter = []
            this.filter_sort.forEach(key => {

                if(!key_filter.includes(key)){
                    key_filter.push(key)
                }

                if(this.filters[key] && this.filters[key].data){
                    for(let sub_key in this.filters[key].data){
                        if(Array.isArray(this.filters[key].data[sub_key]) && this.filters[key].data[sub_key].length > 0){
                            apply_filters += key + '@' + sub_key + '=' + this.filters[key].data[sub_key].join('#') + '|'
                        }else if(typeof this.filters[key].data[sub_key] == 'string' && this.filters[key].data[sub_key] !== ''){
                            apply_filters += key + '@' + sub_key + '=' + this.filters[key].data[sub_key] + '|'
                        }
                    }
                }
            })
            this.$store.dispatch("result/setFilters", apply_filters)
            this.$store.dispatch("advanced_result/clearStatePrix");
            
            console.log('filter : ' + apply_filters)
        
            this.cancel()
        },
        filters_item(items){
            return this.filters[items] ? this.filters[items] : ''
        },

        /**
         * Handle radio button click (for include/exclude choice) as checkbox
         */
        handleClickCSS(event) {
            const items = event.target.getAttribute('data-attribute-items')
            const name = event.target.getAttribute('data-attribute-name')
            const value = event.target.getAttribute('data-attribute-value')

            let data = this.filters[items].data[name] 
            var elements = document.querySelectorAll('span[data-attribute="'+event.target.getAttribute('data-attribute')+'"]')
            elements.forEach(element => {
                if(data.includes(element.getAttribute('data-attribute-value')) || data.includes(value)){
                    for( var i = 0; i < data.length; i++){ 
                        if ( data[i] === element.getAttribute('data-attribute-value') || data[i] === value) { 
                            data.splice(i, 1); 
                        }
                    }
                }
            })
            this.filters[items].data[name] = data

            if(!event.target.classList.contains('active'))
                this.filters[items].data[name].push(value)

        }
    },
    computed: {
        isUserFrench,
        param_filters(){
            return this.$store.getters['result/filters'];
        },
        listAgency(){
            return this.$store.getters['result/listAgency']
        },
        search_agency(){
            return this.$store.getters['result/search_agency']
        },
        agency_item(){
            let item = []
            for(let key in this.listAgency){
                if(this.search_agency.includes(this.listAgency[key].agency_model)){
                    item.push(this.listAgency[key].acronym)
                }
            }
            return item
        }
    },
    props : {
        filter_sort : {
            type : Array
        },
        graph_filter: {
            type : Boolean
        }
    }
}
</script>

<style scoped>
.font-text{
    font-family: 'oxygen-regular', 'sans-serif' !important;
    font-size: 12px !important;
}
</style>